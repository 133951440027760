var isLazyLoad = is_lazy_load ? false : true;
var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

/**
 * FP main visu slider
 */
let intervalId = null;
var spacer = 80;

function setHeightFpSliders() {
    var mainSlideFp = $(".mainSlideFp");
    var thumbSlideFp = $(".thumbSlideFp");

    if (thumbSlideFp.outerHeight() !== (mainSlideFp.find('.img_visu').height() - spacer)) {
        //Set height
        $('.thumbsFpContainer').css('height', mainSlideFp.find('.img_visu').height());
        thumbSlideFp.css('height', (mainSlideFp.find('.img_visu').height() - spacer));
    }
    else {
        //If height set, remove interval
        clearInterval(intervalId);
    }
}

function goToDesc() {
    /**
     * Anchor to product description in FP
     */
    if ($('.goToDescr').length) {

        $('.goToDescr').on('click', function () {
            var toggleDescr = $('#toggleDescr');
            var toggleSpecs = $('#toggleSpecs');
            var redirect_tab = $(this).data('redirect');
            var toggleRedirect = (redirect_tab === "desc" ? toggleDescr : toggleSpecs);

            toggleRedirect.removeClass('actif', function () {
                toggleRedirect.click();

                $('html, body').animate({
                    scrollTop: $(this).offset().top - 200
                }, 600,);
            });
        });
    }
}

function initFpSliders() {
    /**
     * Product main slider
     */
    if ($(".mainSlideFp").length) {
        var mainSlideFp = $(".mainSlideFp");
        var thumbSlideFp = $(".thumbSlideFp");

        setHeightFpSliders ();

        if (thumbSlideFp.outerHeight() !== (mainSlideFp.find('.img_visu').height() - spacer)) {
            //If height not correctly defined, run function on constant interval
            intervalId = setInterval(setHeightFpSliders, 500);
        }
        else {
            //If height set, remove interval
            clearInterval(intervalId);
        }

        const thumbSlideFpSwiper = new Swiper('.thumbSlideFp', {
            direction: 'vertical',
            slidesPerView: 4,
            roundLengths: true,
            navigation: {
                prevEl: '.prevThumbFp',
                nextEl: '.nextThumbFp',
            },
            breakpoints: {
                1620: {
                    slidesPerView: 5,
                },
                //when screen width is = 2560px
                1789: {
                    slidesPerView: 5,
                },
            },
        });

        new Swiper('.mainSlideFp', {
            slidesPerView: 1,
            effect: 'fade',
            roundLengths: true,
            setWrapperSize: true,
            thumbs: {
                swiper: thumbSlideFpSwiper
            },
        });
    }

    /**
     * Product color slider
     */
    if ($('.fp_color_swiper').length) {
        new Swiper('.fp_color_swiper', {

            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: 'auto',
            spaceBetween: 25,
            navigation: {

                prevEl: '.prevColorFp',
                nextEl: '.nextColorFp',
            },
        });
    }

    /**
     * Product associations product slider
     */
    if ($('#assoc_product_slider').length) {
        if ($('.assoc-swiper-wrapper').hasClass('initial-wrapper')) {
            $('.assoc-swiper-wrapper').removeClass('initial-wrapper')
        }

        new Swiper('#assoc_product_slider', {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 13,
            roundLengths: true,
            setWrapperSize: true,
            breakpoints: {
                1300: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            },
            navigation: {
                nextEl: '.assoc-button-next',
                prevEl: '.assoc-button-prev',
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                draggable: true,
            },
        });
    }

    /**
     * Product associations rayon slider
     */
    if ($('#assoc_rayon_slider').length) {
        if ($('.assoc-swiper-wrapper').hasClass('initial-wrapper')) {
            $('.assoc-swiper-wrapper').removeClass('initial-wrapper')
        }

        new Swiper('#assoc_rayon_slider', {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 13,
            roundLengths: true,
            setWrapperSize: true,
            breakpoints: {
                1300: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            },
            navigation: {
                nextEl: '.assoc-button-next',
                prevEl: '.assoc-button-prev',
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                draggable: true,
            },
        });
    }

    // Init swiper categorie page adv
    $('body.cms_page_new .swiperCategories').each(function () {
        if ($(this).find('.swiper-slide').length > 5) {
            swiperCategories = new Swiper('.swiperCategories', {
                preloadImages: false,
                slidesPerView: 5.54,
                slidesPerGroup : 5,
                spaceBetween: 19,
                navigation: {
                    nextEl: '.swiperCategories .swiper-button-next',
                    prevEl: '.swiperCategories .swiper-button-prev',
                }
            });
        } else {
            $(this).addClass("no-arrow");
        }
    });


    /**
     * CMS TG module
     */
    setTimeout(() => {
        if ( $( '.tgCms .swiper-slide' ).length ) {
            var slidePerViewNormal = 3;
            var slidePerGroupNormal = 3;
            var slidePerViewBreak = 4;
            var slidePerGroupBreak = 4;
            var swiperStatus = true;

            // Si il y a moin de/ou 3 produit dans le TG on block l'interaction avec le swiper
            if ($( '.tgCms .swiper-slide' ).length <= 3) {
                swiperStatus = false;
            }

            if ($('body').hasClass('product_page')) {
                slidePerViewNormal = 2;
                slidePerGroupNormal = 2;
                slidePerViewBreak = 3;
                slidePerGroupBreak = 3;
            }

            new Swiper('.tgCms', {
                slidesPerView: slidePerViewNormal,
                slidesPerGroup: slidePerGroupNormal,
                spaceBetween: 26,
                roundLengths: true,
                setWrapperSize: true,
                enable: swiperStatus,
                scrollbar: {
                    el: ".swiper-scrollbar",
                    draggable: true,
                },
                breakpoints: {
                    1200: {
                        slidesPerView: slidePerViewBreak,
                        slidesPerGroup: slidePerGroupBreak,
                    },
                },
                navigation: {
                    nextEl: '.cmstg-button-next',
                    prevEl: '.cmstg-button-prev',
                },
            });
        }
    }, 700);

    /**
     * Recently browsed articles slider
     */
    if ($('#productVisitedSwiper').length) {
        var productVisitedSwiper = new Swiper('#productVisitedSwiper', {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 13,
            roundLengths: true,
            setWrapperSize: true,
            breakpoints: {
                1300: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            },
            navigation: {
                nextEl: '.visited-button-next',
                prevEl: '.visited-button-prev',
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                draggable: true,
            },
        });
    }
}

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {

    return str.replace(rex_php_tags, '');
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {

    return str.replace(rex_script_tags, '');
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {

    return str.replace(rex_comments_tags, '');
}

function stripCombo(str) {

    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

// Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {
    if (window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else
        return (false);
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return (xhr_object.responseText);
    else return (false);
}

function ajax_file_post(url, params) {
    if (window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

    xhr_object.open("POST", url, false);
    xhr_object.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr_object.send(params);

    if (xhr_object.readyState == 4 && xhr_object.status == 200) {
        return xhr_object.responseText;
    }
}

function ajax_file_XML(fichier) {
    if (window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else
        return (false);
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return (xhr_object.responseXML);
    else return (false);
}

// creer l'instanciation de l'objet XHR qu'inporte le navigateur
function getXMLHttpRequest() {

    var xhr = null;

    if (window.XMLHttpRequest || window.ActiveXObject) {
        if (window.ActiveXObject) {
            var tableau_xhr_activeX = [
                'Msxml2.XMLHTTP.6.0',
                'Msxml2.XMLHTTP.3.0',
                'Msxml2.XMLHTTP',
                'Microsoft.XMLHTTP'
            ];

            for (var value in tableau_xhr_activeX) {
                try {
                    xhr = new ActiveXObject(tableau_xhr_activeX[value]);
                    break;
                } catch (error) {
                }
                ;
            }
            ;

        } else {
            xhr = new XMLHttpRequest();
        }
        ;

    } else {
        return null;
    }
    ;
    return xhr;
}

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else {
        var expires = "";
    }

    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
}

/**
 * Switch logo and menu position for tablet mode (< 1270px resolution)
 */
function tabletModeMenu() {
    var siteHeader = document.querySelector(".headerContainer");
    var siteLogo = document.querySelector(".headerLogo");
    var siteMenu = document.querySelector("#wrapper_top_menu");
    var mainMenu = $('.main_menu');
    var shadMenu = $('#shad_menu');

    if (window.innerWidth <= 1270) {

        document.body.classList.add('tabletMode');
        siteHeader.classList.add('tabletMode');
        siteLogo.classList.add('tabletMode');
        siteMenu.classList.add('tabletMode');

        $('#site_head_wrap').off('click', '.tabletMenu').on('click', '.tabletMenu', function () {
            if (mainMenu.hasClass('appear')) {
                mainMenu.removeClass('appear');
                shadMenu.removeClass('actif_tablette');
            } else {
                mainMenu.addClass('appear');
                shadMenu.addClass('actif_tablette');

                setTimeout(function () {
                    mainMenu.overlayScrollbars({
                        overflowBehavior: {
                            x: 'hidden',
                        },
                    });
                }, 250);
            }
        });

        $('.supercateg_item .block_lnk').off('click').on('click', function (e) {

            e.preventDefault();
            var submenuWrapper = $(this).next('.submenu_wrapper');

            if (submenuWrapper.is(':visible')) {
                submenuWrapper.slideUp();
            } else {
                $('.submenu_wrapper').slideUp();
                submenuWrapper.slideDown();
            }
        });

    } else {

        document.body.classList.remove('tabletMode');
        siteHeader.classList.remove('tabletMode');
        siteLogo.classList.remove('tabletMode');
        siteMenu.classList.remove('tabletMode');
    }
}

/**
 *  Pre-header slider
 */
if ($('.promo_swiper').length) {

    const promo_swiper = new Swiper('.promo_swiper', {

        slidesPerView: 1,
        loop: true,
        speed: 2200,
        autoplay: {

            delay: 4500
        },
        allowTouchMove: false,
    });
}

/**
 * Toggle header switch lang
 */
if ($('.switchLangWrapper a').length) {

    var switchLangWrapper = $('.switchLangWrapper');

    $('.switchLang').on('click touch', function () {

        if (switchLangWrapper.hasClass('opened')) {

            switchLangWrapper.slideUp(200, function () {

                switchLangWrapper.removeClass('opened');
            });
        } else {

            switchLangWrapper.slideDown(200, function () {

                switchLangWrapper.addClass('opened');
            });
        }
    });
}

/**
 * Call after express buy to display header basket rollover
 */
function CartShow() {

    var shadMenu = $('#shad_menu');
    var mainMenu = $('#wrapper_top_menu');
    var cartTop = $('#cart_top');

    /* Algolia special case *********/
    if ($('.ais-InstantSearch').hasClass('displayed')) {
        shadMenu = $('#algolia_shade');
        shadMenu.addClass('displayed');
    } else {
        shadMenu.addClass('actif');
    }
    mainMenu.addClass('shaded');
    cartTop.addClass('hover');
    cartTop.addClass('hasItem');
    shadMenu.addClass('actif');

    $('.cart_product_listing').overlayScrollbars({
        overflowBehavior: {
            x: 'hidden',
        },
    });

    setTimeout(function () {

        if ($('.ais-InstantSearch').is(':visible')) {
            shadMenu.removeClass('displayed');
        }

        if (shadMenu.hasClass('actif')) {
            shadMenu.removeClass('actif');
        }

        if ($('#shad').hasClass('actif')) {
            $('#shad').removeClass('actif');
        }

        mainMenu.removeClass('shaded');
        cartTop.removeClass('hover');
    }, 2500);

    $('.headerRollover').each(function () {

        var shadMenu = $('#shad_menu');
        var mainMenu = $('#wrapper_top_menu');

        if ($(this).hasClass('hasItem') || $(this).hasClass('is_logged')) {

            $(this).mouseenter(function () {

                /* Algolia special case *********/
                if ($('.ais-InstantSearch').is(':visible')) {
                    shadMenu = $('#algolia_shade');
                    shadMenu.addClass('displayed');
                } else {
                    shadMenu.addClass('actif');
                }
                mainMenu.addClass('shaded');
                shadMenu.addClass('actif');
                $(this).addClass('hover');
                $('.cart_product_listing').overlayScrollbars({
                    overflowBehavior: {
                        x: 'hidden',
                    },
                });
            }).mouseleave(function () {

                /* Algolia special case *********/
                if ($('.ais-InstantSearch').is(':visible')) {
                    shadMenu = $('#algolia_shade');
                    shadMenu.removeClass('displayed');
                } else {
                    shadMenu.removeClass('actif');
                }
                mainMenu.removeClass('shaded');
                shadMenu.removeClass('actif');
                $(this).removeClass('hover');
            });
        }
    });
}

/**
 * Call after add to wishlist to display header wishlist rollover
 */
function WishShow() {

    var shadMenu = $('#shad_menu');
    var mainMenu = $('#wrapper_top_menu');
    var wishtTop = $('#wishlist_top');

    shadMenu.addClass('actif');
    mainMenu.addClass('shaded');
    wishtTop.addClass('hover');
    wishtTop.addClass('hasItem');
    $('.cart_product_listing').overlayScrollbars({
        overflowBehavior: {
            x: 'hidden',
        },
    });

    setTimeout(function () {

        shadMenu.removeClass('actif');
        mainMenu.removeClass('shaded');
        wishtTop.removeClass('hover');
    }, 2500);
}

/**
 * Add all wishlist rollover products to basket
 */
$(document).on("click", "#btn_top_add_wishlist", function (e) {

    e.preventDefault();
    var selectedProducts = [];
    var selectedProductsAnonymous = [];
    var wishlistProductData = [];
    var isConnect = 0;
    $(".remove_top_wishlist").each(function (index) {

        var id = $(this).attr("data-id");
        wishlistProductData.push({
            productId: $(this).attr("data-productid"),
            colorId: $(this).attr("data-colorid"),
            sizeId: $(this).attr("data-sizeid"),
        });
        selectedProductsAnonymous = JSON.stringify(wishlistProductData);
        selectedProducts.push(id);
        isConnect = $(this).attr("data-isconnect");

    });

    if (isConnect == 0) {
        moveProductFromWishlistToCart(selectedProductsAnonymous, true);
    } else {
        moveProductFromWishlistToCart(selectedProducts);
    }

});

function moveProductFromWishlistToCart(array_wishlist_product_ids, isAnonymous = false) {

    if (isAnonymous) {
        $.post(path_relative_root + create_link('ajax_add_panier_wishlist'), {selectedProducts: array_wishlist_product_ids}, function (resp) {
            if (resp == "ok") {
                document.location.href = path_relative_root + create_link('order_basket');
            }
        });
    } else {
        $.post(path_relative_root + create_link('ajax_add_panier_wishlist'), {data: array_wishlist_product_ids.join(",")}, function (resp) {
            if (resp == "ok") {
                document.location.href = path_relative_root + create_link('order_basket');
            }
        });
    }
}

/***
 *Client disconnexion
 */
function ajaxDeconnect(path_relative_root) {

    var provenance = window.location.pathname,
        cpt_client = new RegExp(/(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/),
        response;

    // Get the current page's name
    provenance = provenance.substr(provenance.lastIndexOf('/'), provenance.lastIndexOf('.'));

    // This actually disconnects the client
    response = ajax_file(path_relative_root + 'ajax_deconnect.php');

    // If there was an error
    if (!response)
        alert('Erreur lors de la déconnexion.');

    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href = path_relative_root + create_link('connexion_login');

    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

/**
 * LIGHTBOX OPENING
 */
function openMultiShad(id) {

    $("#" + id).addClass("actif");

    if (id == 'lightbox_preco') { // Avoid closing for the preco cart lightbox

        $("#shad").addClass("actif");
    } else {

        $("#shad").addClass("actif").on("click", function () {

            closeMultiShad(id);
        });
    }

    if (id === "zoomBox") {

        $('.zommMainVisu .swiper-slide').mousemove(function () {

            var slideOffset = $(this).offset();
            var relY = -1 * (event.pageY - slideOffset.top);

            $(this).find('img').css('top', relY + 600 + "px");
        });
    }

    if (id == 'selection-offer') {

        var selectionOffer = new Swiper('#selection-offer-content', {

            slidesPerView: 2.5,
            slidesPerGroup: 2,
            spaceBetween: 25,
            roundLengths: true,
            setWrapperSize: true,
            navigation: {
                nextEl: '.assoc-button-next',
                prevEl: '.assoc-button-prev',
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                draggable: true,
            },
        });
    }
}

function show_popup(elem) {
    openMultiShad(elem);
}

/**
 * LIGHTBOX CLOSING
 */
function closeMultiShad(id) {

    $('#shad').removeClass('actif');
    $('#' + id).removeClass('actif');
}

function close_popup(elem) {
    closeMultiShad(elem)
}

function closeRecherche() {

    search_home.value = '';
    shad_menu.classList.remove('actif');

}

function triggerFunctionOnSearchEnd() {

    //closeRecherche();

    /**
     * Search category slider
     */
    if ($('.search_sub_categ').length) {

        var search_sub_categ = new Swiper('.search_sub_categ', {

            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: 'auto',
            spaceBetween: 10,
            navigation: {
                nextEl: ".button-sub-categ-next",
                prevEl: ".button-sub-categ-prev",
            },
        });
    }
}

/**
 * Remove product from cart header rollover
 * @param {*} basket_id
 * @param {*} prod_id
 * @param {*} qte
 */
function ajax_RemoveBasketProd(basket_id, prod_id, qte) {

    $.ajax({
        type: 'post',
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte
        },
        url: path_relative_root + 'ajax_removeBasketProd.php',
        success: function (res) {

            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            $('#cart_top').html(res.datas.html);
            CartShow();
        }
    });
}

/**
 Add to wishlist the selected product from header rollover basket
 */
function topBasketToWish(product) {

    var productId = $(product).attr("data-productId");
    var sizeId = $(product).attr("data-sizeId");
    var colorId = $(product).attr("data-colorId");

    var data = {
        product_id: productId,
        size_id: sizeId,
        color_id: colorId,
    };

    if (!checkProductInWishlist(productId)) {

        $.post(path_relative_root + 'ajax_add_to_wishlist.php?time=' + Date.now(), data, function (resp) {

            if (resp.error) {
                if (resp.message == "login_required") {
                    var html =
                        '<div onclick="closeMultiShad(\'abox\')" class="closeBtn"></div><div class="box_wishlist">' +
                        resp.result.error_message +
                        '<div class="btn_container">' +
                        '<a class="button primary" href="#" onclick="wishlistRedirectLogin();">' +
                        resp.result.btn_connexion +
                        "</a>" +
                        "</div>" +
                        "</div>";

                    if ($('#abox').length) {
                        // If abox exists, injected newly created html inside
                        $('#abox').addClass('wishConnection');
                        $('#abox').html(html);
                    } else {
                        // If it does not exist, create it
                        var abox = '<div id="abox" class="lightbox wishConnection actif"></div>';
                        $('#appContainer').append(abox);
                        $(abox).html(html);
                    }
                    openMultiShad('abox');
                } else {
                    alert(Translator.translate(resp.result.error_message));
                    return false;
                }
            } else {
                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'addToWishlist',
                    });
                }
                var response_basket = ajax_file(path_relative_root + 'ajax_show_wishlist.php');
                $(".block_top_links #wishlist_top").html(response_basket);
                $(".block_top_links #cart_top").removeClass('hover');
                $(".block_top_links #wishlist_top").addClass('hover');
                $(".block_top_links #wishlist_top").addClass('hasItem');
                var shadMenu = $('#shad_menu');
                shadMenu.addClass('actif');
                $(product).addClass('existToWishlistButton');
                addToWishlistUpdateHeader(resp.result.wishlist_size);

                setTimeout(function () {
                    $(".block_top_links #wishlist_top").removeClass('hover');
                 }, 2000);
            }
        });

    } else {
        alert(Translator.translate('err_product_exist_in_wishlist'));
    }
}

/**
 * Remove product from wishlist header rollover
 */
$(document).on("click touch", ".remove_top_wishlist", function (ev) {

    var wishlist_product_id = $(this).attr("data-id");
    var product_id = $(this).attr("data-productid");
    var current_elem = $(this);

    $.post(path_relative_root + 'ajax_remove_from_wishlist.php', {
        id: wishlist_product_id,
        product_id: current_elem.data('productid'),
        color_id: current_elem.data('colorid'),
        size_id: current_elem.data('sizeid')
    }, function (data) {

        var nr;
        if (data.result == undefined) {
            data.result = $('.wish_liste_product .product_ctn').length;

        } else {
            nr = data.result.wishlistLength;
        }

        if (nr == 0) {
            $("#wishlist_top").removeClass('hasItem');
            $(".item.wishlist").hide();
        }

        // MAJ products in rayon
        if ($('a[data-productid = "' + product_id + '"]').length > 0) {
            $('a[data-productid = "' + product_id + '"]').removeClass("existToWishlistButton");
            $('a[data-productid = "' + product_id + '"]').data('wishlistproductid', '');
            $('a[data-productid = "' + product_id + '"]').attr('data-wishlistproductid', '');
        }

        var response_html = ajax_file(path_relative_root + 'ajax_show_wishlist.php');
        $("#wishlist_top").html(response_html);

        $(current_elem.parents('.product-link')).fadeOut(100, function () {

            current_elem.parents('.product-link').remove();
        });
    });
});

/**
 * Move wishlist header rollover product to basket
 */
function topWishToBasket(array_wishlist_product_ids) {

    $(this).addClass('loading');
    // Use jQuery to find the div with the specific data-productId and class add_basket
    var divElement = $('.add_basket').filter(function() {
        return $(this).data('productid') == array_wishlist_product_ids[0];
    });

    if (divElement.length > 0 && divElement.data('isconnect') == '0') {
        $.post(path_relative_root + create_link('ajax_add_panier_wishlist'), {
            productId: divElement.data('productid'),
            colorId: divElement.data('colorid'),
            sizeId: divElement.data('sizeid')
        }, function (resp) {
            if (resp == "ok") {
                document.location.href = create_link('order_basket');
            }
        });
    } else {
        $.post(path_relative_root + create_link('ajax_add_panier_wishlist'), {data: array_wishlist_product_ids.join(",")}, function (resp) {
            if (resp == "ok") {
                document.location.href = create_link('order_basket');
            }
        });
    }
}

/**
 * Shows button's underlying loader and triggers defined action
 */
var elmt;
var loader;
var prev_display;

function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function () {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                //window.setTimeout(checkAllForm, 1000, tabError, 0, path_relative_root, id_form);
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, idCouleur, itm_rr_id) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var list = $('.qte_select_wrapper', form).get(0);
    var plnb = form.getValue('produit_lot_nb');
    var qty = (qty !== undefined) ? qty : 1;

    var pcol = form.getValue('idCouleur_' + id + '_' + itm_rr_id);
    if (typeof (pcol) == 'undefined') {
        var pcol = form.getValue('idCouleur');
    }
    var val_color = form.getValue(pcol);
    if (val_color == '0') {
        val_color = 'none';
    }

    var psze = form.getValue('idTaille');
    var val_size = form.getValue(psze);
    if (val_size == '0' && !(plnb > 0)) {
        val_size = 'none';
    }

    if (idCouleur !== undefined) {
        val_color = idCouleur;
        $('#' + pcol).val(idCouleur);
        $("#color_" + idCouleur).attr("checked", "checked");
    }

    var data = {
        idprod: form.getValue('produit_id'),
        idcouleur: val_color,
        idtaille: val_size
    };

    if (from == 'basket') {
        data.suff = form.getValue('produit_id');
    }

    if (plnb) {
        data.produit_lot_nb = plnb;
    }

    $.ajax({
        url: path_relative_root + 'ajax_liste_qte.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        data: data,
        success: function (res) {
            var new_select;
            if (list) {
                list.innerHTML = res.trim();

                if (qty) {

                    new_select = form.elements.namedItem('qteProd');

                    if ($('[value="' + qty + '"]', new_select).length > 0) {

                        new_select.value = qty;
                    } else {

                        new_select.value = $('option:last', new_select).val();
                    }
                }
            }

            if ($("#date_du_ajax").val() == 0 && $("#date_au_ajax").val()) {
                $("#delivery_delay").hide();
            } else {
                $("#delivery_delay").show();
                $("#date_du").html($("#date_du_ajax").val());
                $("#date_au").html($("#date_au_ajax").val());
            }

            $('#liste_qte').html(res);
            $(".eclatProd").show();
            $(".precoDeliveryDate").show();

            // PRODUIT EN PRECOMMANDE
            if ($('#isPreorder').length > 0 && $('#isPreorder').val() === 'oui') {
                $("#btn_add_cart").show();
                $("#btn_alert_stock").hide();
                $("#btn_add_cart").css("visibility","visible");
            } else if ($('#case_ajax') !== 'undefined') {
                if ($('#case_ajax').val() == '5' || $('#case_ajax').val() == '6' || $('#case_ajax').val() == '7') {
                    $(".eclat_nouveaute").removeClass('cache').show();
                    $(".eclat_epuise").hide();
                    $(".delivery_date").show();
                    $(".eclatProd").show().removeClass('cache');

                    $("#btn_alert_stock").removeClass('cache').show();
                    $("#btn_add_cart").css("visibility","hidden");
                } else if ($('#case_ajax').val() == '8') {
                    $(".eclat_epuise").removeClass('cache').show();
                    $(".delivery_date").hide();
                    $(".eclat_nouveaute").hide();
                    $(".eclatProd").hide();

                    $("#btn_add_cart").css("visibility","hidden");
                    $("#btn_alert_stock").hide();
                } else { // Case 1 / 2 / 3 / 4
                    $(".eclat_epuise").hide();
                    $(".eclat_nouveaute").hide();
                    $(".eclatProd").show().removeClass('cache');

                    $("#bloc_add_basket").show();
                    $(".delivery_date").show();
                    $("#btn_add_cart").removeClass('cache').show();
                    $("#btn_add_cart").css("visibility","visible");
                    $("#btn_alert_stock").hide();
                }
            }

            // DATALAYER V2
            pushIntoDatalayer(res.dataLayer_object);
        }
    });
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id, type, modeleItemId, has_stock, dateArrivageJJA) {

    var control, prod_id;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    if (this.checked) {

        control = form.elements.namedItem(form.getValue('idCouleur'));

        if (typeof control == "undefined") {

            var produit_id = document.getElementById('produit_id').value;
            control = document.querySelector('#couleurProd_' + produit_id);
        }

        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();

        if (is_regroup) {

            loadProduct.call(form, prod_id, 'couleur', '', itm_rr_id, has_stock, dateArrivageJJA);
        } else {

            // On regarde si le produit est dejà dans la wishlist
            if (!is_achat_express_v2) {

                checkProductInWishlist(prod_id, itm_rr_id);
            }

            var img = $(this).data('pic');

            if (modeleItemId) {

                changeVisu('slide-' + modeleItemId, img);
            } else {

                changeVisu('img_large', img);
            }

            if (type == 'product') {

                $('.mainSlideFp .swiper-wrapper .swiper-slide-active img').attr('src', img);
            } else if (type == 'association' || type == 'recently') {

                $('.swipper-slide').removeClass('active');
                $('#itm-' + prod_id).addClass('active');
                if ($('#itm-' + prod_id + ' .w-slider-container .w-slider-wrapper').length) {

                    $('#itm-' + prod_id + ' .w-slider-container .w-slider-wrapper').find('span:first').find('img').attr('src', img);
                } else {

                    $('#itm-' + prod_id + ' .swiper-container .swiper-wrapper').find('img:first').attr('src', img);
                }
            }

            if ($('.product_page .form_itm.modele').length > 0) {

                $('.mainSlideFp .swiper-wrapper .swiper-slide-active img').attr('src', img);
            } else {

                $('#itm-' + prod_id + ' .w-slider-container .w-slider-wrapper').find('span:first').attr('data-src', img);
            }

            getAvailableQuantity(prod_id, false, '');
            setAvailableSizes.call(this);
        }

        window.scrollTo(0, $(window).scrollTop() + 2);
    }
}

/**
 * Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible
 */
function lightboxAlertStock() {

    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alert(translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {

        if (isFormVisible) {

            $('.bloc_add_alert_form').slideUp('fast');
        }

        if (isMessageVisible) {

            $('.bloc_add_alert_confirmation').slideUp('fast');
        }

        if (iserrorVisible) {

            $('.bloc_add_alert_erreur').slideUp('fast');
        }
    } else {

        $('.bloc_add_alert_form').slideDown('fast');
    }
}

/* On cache le cta "M'avertir de sa Disponibilité" */
$('#bloc_add_alert #btn_alert_stock').click(function(){
    $( this ).css("display", "none");
    $("#bloc_add_alert_form").css("display", "flex");
} );

/* On cache le formulaire "Votre email" pour "M'avertir de sa Disponibilité" par defaut */
$("#bloc_add_alert_form").css("display", "none");

/* On fais apparaite le cta "M'avertir de sa Disponibilité" apres avoir confirmer le formulaire "Votre email" */
$('.btn_container .btn_newsletter_subscription_confirmation').click(function(){
    $('#bloc_add_alert #btn_alert_stock').css("display", "block");
    $("#bloc_add_alert_form").css("display", "none");
});

function closeAlerteStock(elem) {

    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
    $("#prod_info_" + elem + " .rollover_left").show();

    // Reset mail input
    $('.wrapper_add_color_' + elem).show().removeClass("loading");
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
}

function getWishlistProducts() {
    let array_elements = document.querySelectorAll('a[data-productid]');

    let array_ids = [];
    if (typeof array_elements != 'undefined' && array_elements.length > 0) {
        for (el of array_elements) {
            var id = el.getAttribute('data-productid');

            if (id !== '' && id !== 'undefined') {
                array_ids.push(id);
            }
        }
        let data = JSON.parse((JSON.stringify(Object.assign({}, array_ids))));

        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_all_wishlist.php',
            data: data,
            success: function (response) {
                if ((typeof response != 'undefined')) {
                    let array_product_id;

                    try {
                        array_product_id = JSON.parse(response);
                    } catch (e) {
                        return;
                    }

                    for (product_wishlist in array_product_id) {
                        $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').addClass("existToWishlistButton");
                        $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').attr('data-wishlistproductid', array_product_id[product_wishlist].wishlist_product_id);
                    }
                    addToWishlistUpdateHeader(response.length);
                }
            }
        });
    }
}

function loadProduct(new_id, type, page, itm_rr_id, triggerType, is_regroup = true) {
    var form = this;
    var old_id = this.getValue('produit_id');
    var cpt = this.getValue('cpt');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;
    triggerType = (triggerType !== undefined) ? triggerType : '';

    //Get original width and height of changed item, preserving style
    var oldModel = $(this).attr('id') !== '' ? $(this).attr('id').replace('prod_info_', '#itm-') : '',
        newModel = oldModel !== '' ? oldModel.replace('_', '-') : '',
        newWidth = $(newModel).css('width');

    if (this.elements.namedItem('qteProd')) {
        var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;

    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id: old_id,
        produit_principal: old_id,
        couleurProd: col_id,
        tailleProd: sze_id,
        act: act,
        qteProd: qte_itm,
        hdn_product_id: old_id,
        hdn_is_model: is_model,
        produit_id_new: new_id,
        type: type,
        type_page: (page !== undefined && page !== '') ? page : 'product',
        is_achat_express: is_wishlist ? 1 : 0,
        is_achat_express_v2: is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod: cpt_prod - 1
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_get_product_regroup_ref.php',
        data: data,
        beforeSend: function () {

        },
        success: function (res) {
            try {

                res = $.parseJSON(res);
            } catch (e) {

                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {
                var datas = res.datas;

                if (data.type_page == 'product') {

                    var $html_main = $(stripCombo(datas.html_main));
                    var color = $("#ligne_couleur").html();

                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var info_wrapper = $('.product_info_wrapper', $html_main);
                    var zoom_box_wrapper = $('#zoom_box_wrapper', $html_main);

                    if (data.is_achat_express_v2) {

                        if (is_regroup) {
                            // Photo et information
                            var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');
                            $('#itm-' + itm_id).replaceWith($html_main);

                            var new_itm_id = new_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');
                            $('input.color_' + new_id + itm_rr_id).prop('checked', false);

                            $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                        }

                        getWishlistProducts();

                        // ATMO SPECIAL CASE INTEGRATION : Once you have selected a color, in achat_express_v2 --> Display sizes
                        if (triggerType === 'colorChange') {
                            // Hide colors if visible
                            if ($("#ligne_couleur_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).is(':visible')) {
                                $("#ligne_couleur_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).fadeOut(600);
                            }
                            // Display sizes right after
                            setTimeout(function () {
                                $("#ligne_pointure_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).fadeIn(600);
                            }, 600)
                        } else if (triggerType === 'sizeChange') {
                            // On size change -> be sure the product is available
                            $('#tailleProd_' + (new_id + itm_rr_id)).val(sze_id);

                            // En fonction de la couleur on regarde si le produit est disponible -> si getAvailableQuantity renvoi true, on ajoute au panier
                            if (getAvailableQuantity(new_id, qte_itm, 'product', data.couleurProd, itm_rr_id)) {
                                waitingAddToBasket(null, document.getElementById('prod_info_' + new_id + itm_rr_id), '', itm_rr_id);
                            } else {
                                // If getAvailableQuantity renvoi false == no stock, on affiche l'alerte stock;
                                if ($("#ligne_pointure_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).is(':visible')) {
                                    $("#ligne_couleur_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).is(':visible').fadeOut(600);
                                }

                                // If no stock, show alert stock and hide add to basket btn
                                $("#bloc_add_color_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).hide();
                                $("#bloc_add_alert_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).show();
                            }
                        }
                    } else {

                        var $html_middle = $(stripCombo(datas.html_middle));
                        var avisVerifiesReviews = $('w-netreviews-comments', $html_main);
                        if ($('w-netreviews-comments', $html_main).length === 0) {
                            avisVerifiesReviews = $('#w-netreviews-comments', $html_main);
                        }
                        var product_refext = $('#produit_refext', $html_main).val();
                        var assoc_product_wrapper = $('.assoc_product', $html_main);
                        var assoc_rayon_wrapper = $('.assoc_rayon', $html_main);
                        var cms_page_wrapper = $('.product_cms_bloc', $html_main);

                        $('.item_price_wrapper', $html_main).next('div').after($html_middle).remove();

                        var contents = $('<div />').html($html_main);

                        var video = $('.wrapper_video', contents);

                        // Photo et information
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());

                        // Description compo, livraison, guide tailles
                        $('.product_info_wrapper', '#site_global_wrap').html(info_wrapper.html());

                        // Association produit
                        $('.assoc_product', '#site_global_wrap').html(assoc_product_wrapper.html());

                        // Association rayon
                        $('.assoc_rayon', '#site_global_wrap').html(assoc_rayon_wrapper.html());

                        // Cms page module
                        $('.product_cms_bloc', '#site_global_wrap').html(cms_page_wrapper.html());

                        // depending on triggerType, update either color checked or size selected
                        if (triggerType == 'sizeChange') {
                            $('#tailleProd .size').prop('selected', false);
                            $('#tailleProd option#size_' + data.tailleProd).prop('selected', true);
                        }

                        if (triggerType == 'colorChange') {
                            $('.product_info_wrapper input.color').prop('checked', false);
                            $('.product_info_wrapper input#color_' + data.couleurProd).prop('checked', true);
                        }

                        if (document.getElementById("alma-widget") !== null) {
                            initAlmaWidget();
                        }

                        goToDesc();
                        initFpSliders();

                        $("#ligne_couleur").html(color);
                        $('#videoBox', '#site_global_wrap').remove();
                        $('#shade').after(video);

                        $('.breadcrumb.current').html('<span>' + datas.product.titreobjet + '</span>');

                        //zoom box
                        $('#zoom_box_wrapper').html(zoom_box_wrapper.html());

                        getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd, '', data.couleurProd, itm_rr_id);
                    }

                }

                if (typeof $('#' + old_id + '-' + cpt) !== "undefined") {
                    $('#' + old_id + '-' + cpt).attr("id", datas.product.id + "-" + cpt);
                }

                checkProductInWishlist(datas.product.id, itm_rr_id);

                refreshEventsLoadProduct();
            } else {
                $('#btn_error').fadeIn();
            }
        },
        complete: function () {

            if (is_wishlist) {
                // event sur le bouton d'ajout à la wishlist
                $("#addToWishlistButton").on("click", addToWishlist);
            }

            if ($("#jqzoom .swiper-slide").length > 0) {
                new Swiper('#jqzoom', {
                    direction: 'vertical',
                    slidesPerView: 1,
                    mousewheel: true,
                    navigation: {
                        nextEl: '.thumbNext',
                        prevEl: '.thumbPrev',
                    },
                    thumbs: {
                        swiper: productThumbs
                    },
                    breakpoints: {
                        1300: {
                            direction: 'horizontal',
                        },
                    },
                    preloadImages: false,
                    lazy: true,
                    loadPrevNext: true,
                    loadOnTransitionStart: true
                });
            }

            //Simulates scroll (2px) to load images
            if (!$('.product_page').length) {
                window.scrollTo(0, $(window).scrollTop() + 2);
            }

            // After swiper load, reload dailymotions videos
            try {
                initDailymotionVideos();
            } catch (e) {
                console.error(e);
            }

            $(document).on("click", ".avis_alma .item_ratings", function() {
                $('html, body').animate({

                    scrollTop: $('#w-netreviews-comments').offset().top - 200
                }, 600,);
            });
        }
    });
}

/* NETREVIEWS
    First switch, ID is in DOM, replace by ID
    After the first switch replace by tag name
*/
function updateReviews(avisVerifiesReviews, product_refext) {

    if ($("w-netreviews-comments").length > 0) {
        $("w-netreviews-comments", '#site_global_wrap').replaceWith(avisVerifiesReviews.wrap('<p/>').parent().html());
    } else {
        $("#w-netreviews-comments", '#site_global_wrap').replaceWith(avisVerifiesReviews.wrap('<p/>').parent().html());
    }
    // Reload vuejs netreviews
    if (typeof wShop !== 'undefined') {
        // Netreviews reviews
        if (wShop.$refs.wNetreviewsComments != 'undefined') {
            wShop.$refs.wNetreviewsComments.reload(product_refext);
        }
    }
}

$(document).on('click', '#site_global_wrap .netreviews_stars', function () {
    $('html, body').animate({scrollTop: $('#avProductWrapper').offset().top - $("#site_head_wrap").height()}, 1000);
});

/**
 * Refresh product after ajax event
 */
function refreshEventsLoadProduct() {
    if ($(".product_page")) {

        initFpSliders();
        refreshToggleProduct();

        if (wShop.$refs.wAlertestockForm) {
            wShop.$refs.wAlertestockForm.reloadBloc += 1;
        }
    }
}

/**
 * Express buy
 * @param product_id : id du produit
 * @param regroup_ref_id : id du regroupement (si existant)
 * use in : app/views/_components/product/achat_express.php
 */
function achatExpressV2(product_id, regroup_ref_id) {
    var btn = $(this);

    // Loader
    btn.parent('.form_submit').addClass('loading');

    var elem = regroup_ref_id ? '_' + product_id + regroup_ref_id : '_' + product_id;
    var form = document.getElementById('prod_info' + elem);

    var color_id = form.elements['itm_color'].value;
    var size_id = form.elements['itm_size'].value;
    // Vérification si la taille sélectionnée n'est pas désactivée ce qui permet d'afficher l'alerte stock
    var tailleProdCrossSell = $('#qteProd_' + product_id + regroup_ref_id);
    if (($('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)) != undefined && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).length == 1 && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).hasClass('disabled')) || ($('#tailleProd_' + product_id + '_' + regroup_ref_id).value == '0') || (typeof tailleProdCrossSell[0] != "undefined" && tailleProdCrossSell[0].value == "0")) {
        $(this).parent().hide();
        $('#bloc_add_alert' + elem).show();
    } else { // On ajoute bien le produit en faisant les vérifications sur la taille et la couleur
        // On regarde si la personne a sélectionenr la taille et la couleur
        if (color_id !== '' && size_id !== '') {
            if ($('.wrapperCartAssociations').length) {

                waitingAddToBasket(event, form, 'basket', regroup_ref_id);
            } else {

                waitingAddToBasket(event, form, '', regroup_ref_id);
            }

            setTimeout(function () {
                btn.parent('.form_submit').removeClass('loading');
            }, 2000);
        } else if (color_id === '' && size_id === '') {
            alert(Translator.translate('choose_a_size_and_a_color'));
            // Loader
            btn.parent('.form_submit').removeClass('loading');
        } else if (color_id === '') {
            alert(Translator.translate('choose_a_color'));
            // Loader
            btn.parent('.form_submit').removeClass('loading');
        } else if (size_id === '') {
            alert(Translator.translate('choose_a_size'));
            // Loader
            btn.parent('.form_submit').removeClass('loading');
        }
    }
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {
    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    evt.preventDefault();

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

        loader = $(this.nextElementSibling);

        loader.addClass('loading');
    } else {

        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {

        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {

        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
    }
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id) {
    var id = '';
    var prix_id = 'prixU';
    if (from == 'basket' || is_not_produit == true || is_achat_express) {

        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {

            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {

        if (from == 'basket') {

            location.reload();
            return false;
        }

        var type = '';
        if (typeof (array_panier.error) == 'undefined') {

            if (array_panier[1] == 'false' || array_panier.length == 0) {

                type = 'err';
            }

            omnitureEvent = '';
            var response = ajax_file(path_web + 'ajax_show_basket.php');
            $("#cart_top").html(response);
            CartShow();

            if (array_panier[1] === 0) {

                $('#cart_length').html(array_panier[1] + ' ' + Translator.translate('items'));

                omnitureEvent = '';
            } else if (array_panier[1] == 1) {

                $('#cart_length').html(array_panier[1] + ' ' + Translator.translate('item'));
                $('.mini_cart', '#top_wrapper').attr('href', path_web + create_link('order_basket'));

                omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
            } else {

                $('#cart_length').html(array_panier[1] + ' ' + Translator.translate('items'));
                $('.mini_cart', '#top_wrapper').attr('href', path_web + create_link('order_basket'));

                omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
            }

        } else {

            alert(array_panier.error.msg, false);
        }

        if (loader) {

            loader.removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {

            void (s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void (s.t());
        }

        // DATALAYER V2
        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            var dl_objet = array_panier[5];
            if (is_achat_express) {

                try {
                    var new_dl_objet = JSON.parse(dl_objet);
                    new_dl_objet.ecommerce.is_achat_express = true;
                    dl_objet = JSON.stringify(new_dl_objet);
                } catch (e) {
                }
            }
            pushIntoDatalayer(dl_objet);
        }

        $("#shad").after($("#modbox"));
        $("#lightbox_achat_express, .lightbox_achat_express").hide();

        newshowmodal(produit_id, is_not_produit);
    } else {

        if (loader) {

            loader.removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {

        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {

                svn[a].style.visibility = "hidden";
            }
        }
    }
    return false;
}

function newUpdatePanier(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur) {
    if (i != '') {
        i = '_' + i;
        prixU = $('#prixU' + i).val();
    }

    var couleurs = $('#couleurProd' + i);

    if ($('#produit_id').val() == idProduit || Object.keys(couleurs).length === 0) {
        couleurs = $('#couleurProd');
    }

    var tailles = $('#tailleProd' + i);
    if (Object.keys(tailles).length === 0) {
        tailles = $('#tailleProd');
    }
    var $alertbox = $('#alertbox1');
    var res = '';
    var html_err = '';
    var flagError = false;

    html_err = '<div class="txt_alert"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="button primary">OK</a></div>';

    if ($alertbox.length == 0) {

        $alertbox = $('<div id="alertbox1" class="lightbox">' + html_err + '</div>');
        $('body').append($alertbox.hide());
    }

    if (tailles.length && tailles.val() == '') {

        res = Translator.translate('js_error_size');
        flagError = true;
    } else if (couleurs && couleurs.val() == '') {

        res = Translator.translate('js_error_couleur');
        flagError = true;
    } else if (document.getElementById('qteProd' + i) && document.getElementById('qteProd' + i).value == '') {

        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {

        $alertbox.find('.txt_alert').html(res);
        openMultiShad('alertbox1', 'fixed');

        return false;
    } else {
        var qte = 0;
        var quantity = $('#qteProd' + i);
        if (Object.keys(quantity).length === 0) {
            quantity = $('#qteProd');
        }
        if (typeof quantity !== "undefined") {
            var qte = quantity.val();
        }
        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != 'none')
            idCouleur = couleurs.val();
        ;
        if (tailles && tailles.val() != 'none')
            idTaille = tailles.val();

        var array_response = [];
        var response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);

        if (response) {

            if (response.indexOf("//") <= 0) {
                try {

                    array_response = JSON.parse(response);
                } catch (e) {

                    console.error('JSON ERROR: could not parse data');

                    return false;
                }
            } else {

                array_response = response.split("//");
                var designation = array_response[0];

                if (titreObjet.match('^[0-9]')) {

                    if (designation == 1) {

                        designation += ' lot de';
                    } else {

                        designation += ' lots de';
                    }
                }

                designation += ' ' + titreObjet;

                if (document.getElementById(idElTaille)) {

                    if (document.getElementById(idElTaille).selectedIndex) {

                        designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;
                    } else if (lastTaille != '') {

                        designation += ' - Taille ' + lastTaille;
                    }
                }

                if ($('[name="' + idElCouleur + '"]').length > 0) {

                    if ($('[name="' + idElCouleur + '"]:checked').length > 0) {

                        designation += ' - ' + $('[name="' + idElCouleur + '"]:checked').get(0).nextElementSibling.title.trim();
                    } else if (lastCouleur != '') {

                        designation += ' - ' + lastCouleur;
                    }
                }

                array_response[0] = designation;
            }
        }

        return array_response;
    }
}

/***** FUNCTION PUSH INTO DATALAYER  *****/
function pushIntoDatalayer(data) {

    if (typeof (data) == 'object') {

        window.dataLayer.push(data);
    } else if (typeof (data) == 'string') {

        window.dataLayer.push(JSON.parse(data));
    }
}

/**
 * Trigger filters openeing
 */
function filterTrigger(event) {

    if ($('#filter_sticky').length) {

        var trigger = $('#filter_sticky');
        var filter = $('#trigger_filtre');
        var shadMenu = $('#shad_menu');
        var mainMenu = $('#wrapper_top_menu');

        if (!trigger.hasClass('triggered')) {

            $(event.target).addClass("is_checked");
            trigger.addClass('triggered');
            filter.addClass('show');
            mainMenu.addClass('shaded');
            shadMenu.addClass('actif');
            var wichFilter = $(event.target).attr("id");

            if ($("." + wichFilter).length) {
                $(".filters_dropdown_bloc input.filter_checkbox_hide").each(function (index) {
                    $(this).prop("checked", false);
                });

                $("." + wichFilter).prop('checked', true);
                /*
                $('#filter_sticky .filters_container_wrapper').overlayScrollbars({
                    overflowBehavior: {
                        x: 'hidden',
                    },
                });
                */
            }

        } else if (trigger.hasClass('triggered')) {

            $(".filters_dropdown_content input[type='checkbox']").each(function (index) {

                $(this).prop("checked", false);
            });
            $(".filtre_elem").each(function (index) {

                $(this).removeClass("is_checked");
            });

            $(event.target).addClass("is_checked");
            closeFiltre();
        }

        if (trigger.hasClass('triggered')) {

            shadMenu.on("click touch", function () {

                closeFiltre();
            });
        }
    }
}

$(document).on("click", ".filter_checkbox_hide", function() {

    var currentClass = $(this).attr("class");

    $(".filter_checkbox_hide").each(function (index) {
        if (currentClass != $(this).attr("class")) {

            $(this).prop('checked', false);
        }
    });
});

/**
 * Trigger filters openeing
 */
function toggleAlgo() {

    if ($('#brand-list').length) {

        var trigger = $('#brand-list');

        if (!trigger.hasClass('triggered')) {

            trigger.addClass('triggered');

        } else if (trigger.hasClass('triggered')) {

            closeAlgoFilters();
        }
    }
}

function closeAlgoFilters() {

    $('#brand-list').removeClass('triggered');
}

/**
 * Init price range filter
 */
document.onload = function () {

    if ($("#slider-range").length) {
        var prix_min = $("#amount1").val();
        var prix_max = $("#amount2").val();
        $("#amount1").val(prix_min) + siteCurrency.symbol_left;
        $("#amount2").val(prix_max) + siteCurrency.symbol_left;
        $("#slider-range").slider("option", {
            min: parseInt(prix_min),
            max: parseInt(prix_max),
            values: [parseInt(prix_min), parseInt(prix_max)],
        });

        $(".ui-slider-range").width("100%");
        $(".ui-slider-range").css("left", "0%");
        $(".ui-slider-range")
            .next()
            .css("left", "0%");
        $(".ui-slider-range")
            .next()
            .next()
            .css("left", "100%");
    }
}

/**
 * Reset all fiters
 */
function initAllFilters() {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.click();

                // Remove is_checked classes
                if ($(elm).hasClass('is_checked')) {
                    $(elm).removeClass('is_checked');
                }
            }
        }
    });

    $('a.tri_price.actif').removeClass('actif');
    $(".filtre_elem").each(function (index) {
        $(this).removeClass("is_checked");
    });
    $(".filters_dropdown_content input[type='checkbox']").each(function (index) {
        $(this).prop("checked", false);
    });

    var prix_init_min = $('input[name="initial_min_price"]').val();
    var prix_init_max = $('input[name="initial_max_price"]').val();
    var minPriceHandler = $('#slider-range .ui-slider-handle:first');
    var maxPriceHandler = $('#slider-range .ui-slider-handle:last');

    $("#amount1").val(prix_init_min);
    $("#amount2").val(prix_init_max);
    $("#slider-range").slider("option", {
        min: parseInt(prix_init_min),
        max: parseInt(prix_init_max),
        values: [parseInt(prix_init_min), parseInt(prix_init_max)],
    });

    minPriceHandler.html('<span class="handler_price">' + prix_init_min + siteCurrency.symbol_right + '</span>');
    maxPriceHandler.html('<span class="handler_price">' + prix_init_max + siteCurrency.symbol_right + '</span>');

    $('#trigger_filtre .initFilters').hide();
}

/**
 * Count active filters in rayon
 */
function countItemFilter(input, translation) {

    var filterName = input.name;
    var filters = $('input:checked[name=' + filterName + ']');
    var countFilters = filters.length

    if (countFilters > 0) {

        $("#trigger_filtre [data-filtername='" + filterName + "']").addClass('actif');
        if ($("#trigger_filtre [data-filtername='" + filterName + "']").length) {

            if ($("#trigger_filtre [data-filtername='" + filterName + "']").find('.count_filters').length) {

                $("#trigger_filtre [data-filtername='" + filterName + "'] .count_filters").html(countFilters);
            } else {

                $("#trigger_filtre [data-filtername='" + filterName + "']").append('<span class="count_filters">' + countFilters + '</span>');
            }
        } else if ($("#trigger_filtre [data-filtername='" + translation + "']").length) {

            if ($("#trigger_filtre [data-filtername='" + translation + "']").find('.count_filters').length) {

                $("#trigger_filtre [data-filtername='" + translation + "'] .count_filters").html(countFilters);
            } else {

                $("#trigger_filtre [data-filtername='" + translation + "']").append('<span class="count_filters">' + countFilters + '</span>');
            }
        }
    } else {

        if ($("#trigger_filtre [data-filtername='" + filterName + "'] .count_filters").length) {

            $("#trigger_filtre [data-filtername='" + filterName + "']").removeClass('actif');
            $("#trigger_filtre [data-filtername='" + filterName + "'] .count_filters").remove();
        }
    }
}

function filterTriggerIn(event) {
    // class added on input when user filters
    $(event.target).toggleClass("is_checked");
    $(event.target).click();
}

function filterWriteLabel(event) {
    var wichFilter = $(event.target).text();
    $("#filter_word").text("");
    $("#filter_word").text(wichFilter);
}

function closeFiltre() {
    $('#filter_sticky').removeClass('triggered');
    $('#trigger_filtre').removeClass('show');
    $('#shad_menu').removeClass('actif');
    $('#wrapper_top_menu').removeClass('shaded');
    $(".filtre_elem").each(function (index) {
        $(this).removeClass("is_checked");
    });
    // close filters
    $(".filtre_elem").each(function () {
        if ($(this).addClass("is_checked")){
            $(".filter_checkbox_hide").prop('checked', false);
        };
    });
}

/**
 * Pour charger tous les produits sur une page
 * @author
 * @since
 *
 * @param  String            type       [description]
 * @param  Number            idObj      [description]
 * @param  Number            page       [description]
 * @param  Number            nb_product [description]
 * @param  HTMLElement        btn           le bouton sur lesquel on a cliqué pour charger les element (si undefined pas de probleme)
 *
 * @return void
 */

var bloc_prod_xhr;

function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollTop, fromScrollBottom, fromPagination = false) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        output = {};

    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky', fromPagination);

    if (fromScrollTop || fromScrollBottom) {
        $('#is_loading').val('1');
        $('#initial_page').val(page + 1);
        seeAllBtnClick("remove");

        //Handle pagination with push images
        output.page = $('#list_item .item:not(.push)').length;
        output.handle_push = 'false';
    }

    if (page !== 'all') {

        if (nb_product === 'all') {

            $('.see_all').html(
                '<img src="' + path_relative_root + 'img/loader.gif" width="20" height="20" />'
            );

        }

        if (bloc_prod_xhr) {

            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + 'ajax_reload_products.php',
            type: 'get',
            data: output,
            success: function (res) {


                var old_elm = document.getElementById('list_item');
                var content;
                var itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {

                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');

                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                if (content !== '') {

                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".autoLoad").fadeTo("fast", 0);
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('totalElems').value = itm_length;
                    paginate(page + 1);
                    old_elm.insertAdjacentHTML('beforeend', content);

                    /* Add total product number of filtered items */
                    $("#validate_filter").html(Translator.translate("see") + " (" + itm_length +")");

                    if (nb_product === 'all') {
                        $('.see_all').html('');
                    }
                }
            },
            complete: function () {

                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');
                    seeAllBtnClick("add");
                }

                if (!fromScrollBottom && !fromScrollTop) {
                    setTimeout(function () {
                        $("html, body").animate({
                            scrollTop: 0
                        }, 200);
                    }, 250);
                    //reset initiialPage if from filter
                    $("#initial_page").trigger("change");
                }

                total_element = form.getValue('totalElems', parseFloat);

                // Display number of product on the right side of top ray filters
                $( '#nbrFilteredProd span' ).html( total_element );
                
                // Modify "Produit" based on the number of products
                var label = total_element > 1 ? Translator.translate('products') : Translator.translate('product');
                $('#nbrFilteredProd').html('<span>' + total_element + '</span>' + label);

                /**
                 * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                 */
                var productLoaded = $('#list_item .item:not(.push)').length;
                var percentLoaded = (productLoaded * 100 / total_element);

                if (productLoaded >= total_element) {
                    $('.nbProdDisplayed').html(Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + productLoaded);
                } else {
                    $('.nbProdDisplayed').html(Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element);
                }
                $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

                $('.loader_scroll').removeClass('loading');

                if (productLoaded >= total_element) {
                    $('.loader_scroll').hide();
                } else {
                    $('.loader_scroll').show();
                }

                setTimeout(function () {

                    $('.pagerNav').addClass('appear');
                }, 500);
            }
        });
    }
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {

    page = page || 1;
    var page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {

        generateNewBlocSearch(page_bloc);
    } else {

        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop, true);
    }
}

function paginate(page) {

    if (document.getElementById('filter_sticky')) {
        var form = document.getElementById('filter_sticky'),
            total_element = form.getValue('totalElems', parseFloat),
            nb_prod = form.getValue('nb_prod', parseFloat),
            nb_total_page = Math.ceil(total_element / nb_prod),
            pagination = $('.pager_wrapper'),
            nb_prod_default = form.getValue('nb_prod_default', parseFloat);
        see_all = form.getValue('see_all', parseFloat);

        page = page || 1;

        $.ajax({
            url: path_relative_root + 'ajax_reload_pagination.php',
            type: 'get',
            data: {
                page: page,
                link: 'paginationGoToPage()',
                nb_total_page: nb_total_page
            },
            success: function (res) {

                var productLoaded = $('#list_item .item:not(.push)').length;
                var percentLoaded = (productLoaded * 100 / total_element);

                pagination.each(function () {

                    if (nb_prod != 1000 && nb_prod < total_element) {
                        this.innerHTML = res + '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product"</div>';
                    } else if (total_element > nb_prod_default) {
                        this.innerHTML = '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product"</div>';
                    } else {
                        this.innerHTML = '';
                    }
                });

                initDailymotionVideos();
            }
        });
    }
}

function zoomImg(slide) {

    $('#zoom_box_wrapper').css('opacity', 0);

    window.setTimeout(function () {

        var zommMainVisu = $(".zommMainVisu");
        var zommThumbs = $(".zommThumbs");

        zommThumbs.css('height', $('.thumbsZoomContainer').height());

        zommThumbs = new Swiper('.zommThumbs', {

            direction: 'vertical',
            slidesPerView: 5,
            roundLengths: true,
            navigation: {

                prevEl: '.zoomThumbsPrev',
                nextEl: '.zoomThumbsNext',
            },
            breakpoints: {
                1620: {
                    slidesPerView: 5,
                },
            },
        });

        zommMainVisu = new Swiper('.zommMainVisu', {

            slidesPerView: 1,
            //effect: 'fade',
            roundLengths: true,
            setWrapperSize: true,
            thumbs: {

                swiper: zommThumbs
            },
            navigation: {

                prevEl: '.zoomProdPrev',
                nextEl: '.zoomProdNext',
            }
        });

        if (slide == null) {
            if (typeof (productMainVisu) != 'undefined') {
                zommMainVisu.slideTo(productMainVisu.activeIndex);
            }
        } else {
            if ($('.zommThumbs .minivideo').length > 0) {
                zommMainVisu.slideTo(slide);
            } else {
                zommMainVisu.slideTo(slide - 1);
            }
        }
    }, 50);

    window.setTimeout(function () {

        $('#zoom_box_wrapper').css('opacity', 1);
    }, 350);

    openMultiShad('zoomBox');
}

/**
 * Displays the store locator popup and initializes the map provider service.
 *
 * @param {boolean} is_find_store - Determines the type of popup to display.
 *                                  If true, the popup will be for finding a store.
 *                                  If false, the popup will be for choosing a favorite store.
 */
function showpdv(is_find_store) {
    var shad, modbox, contents;

    $('html, body').animate({
        scrollTop: 0
    }, 500);

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_search_shop'),
        success: function (res) {

            if (res) {

                shad = document.getElementById('shad');
                modbox = document.getElementById('pdvBox');

                contents = $('<div />').html(res);
                $('#pdvBox').removeClass('deployed');

                // Make sure modbox will show on top of shad
                $(shad).after(modbox);

                // Inject store locator into modbox
                $('.store_locator', modbox).empty()
                    .append($('.storeloc_form', contents))
                    .append($('.liste_distributeur_wrapper', contents).append($('#loading-image', contents)))
                    .append($('script.mapsProvider', contents))
                ;

                var listeMag = document.getElementById('liste_distributeur');
                listeMag.innerHTML = '';

                //wording
                if (is_find_store) {
                    $('.popup_title').html(Translator.translate('find_in_store'));
                    //Add-remove class on the container to differentiate the CSS between: find in store / Choose favorite store
                    $('#pdvBox').addClass('find_art_in_store');
                    $('#pdvBox').removeClass('choose_fav_mag');
                } else {
                    $('.popup_title').html(Translator.translate('choose_favorite_store'));
                    $('#pdvBox').addClass('choose_fav_mag');
                    $('#pdvBox').removeClass('find_art_in_store');
                }

                if (typeof MapsProviderService === 'undefined' || MapsProviderService.provider.name == 'googlemaps') {
                    initialize();
                    geolocalisation();
                    verifPartnerStock();
                } else if (typeof MapsProviderService != 'undefined') {
                    MapsProviderService.updateList();
                }

                $(modbox).addClass('actif');
                $(shad).addClass('actif');
                $(shad).on('click', closepdv);
                $('#content_pdvBox_new').hide();
                $('#content_pdvBox').show();
            }
        }
    });
}

var detailsFavMag = document.getElementById('detail_fav');
$(detailsFavMag).on('click', function () {
    $(this).toggleClass('actif');
    $('.detail_mag').toggleClass('cache');
});

function closepdv() {

    var shad = document.getElementById('shad');
    var modbox = document.getElementById('pdvBox');

    $(modbox).removeClass('actif');
    $(shad).removeClass('actif');
}

/**
 * Filter orders by type
 */
function show_orders_by_type(elementID, type, parentPath, reload) {

    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {

        str_year = '&year=' + year;
    }

    if (reload !== undefined) {

        str_reload = '&reload=true';
    }

    // Gestion de la surbrillance des onglets
    var i = 1;
    while (document.getElementById("com_onglet_" + i)) {

        if (type == i) {

            $("#com_onglet_" + i).addClass("actif");
        } else {

            $("#com_onglet_" + i).removeClass("actif");
        }
        i++;
    }

    var container = document.getElementById(elementID);

    //clearTimeout(time_out);
    if (container) {

        var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);
        if (response) {

            if (reload !== undefined) {

                $('.content_commandes').html(response);
            } else {

                $(container).html(response);
            }
        } else {

            container.innerHTML = '';
        }
    }
}

/**
 * Utiliser pour ajouter un magasin dans ses favoris (boutique_details)
 */
function addToMyFavorites() {

    var is_favorite_elem = document.getElementById('is_favorite');

    var self = $(this);
    var magasin_id = self.attr('data-magasinid');
    var client_id = self.attr('data-clientid');
    var action = self.attr('data-action');

    // Si l'action est de détruire alors on est dans favorites_stores et on souhaite supprimer le bloc en entier
    if (action == 'destroy') {
        var act = 'del';
    } else {
        // On est dans store_details et on doit recupérer le statut actuel du magasin pour savoir si on doit le supprimer ou l'ajouter
        if (is_favorite_elem.value == '1') {
            var act = 'del';
        } else {
            var act = 'add';
        }
    }

    $.ajax({
        url: path_relative_root + 'ajax_manage_favorite_storelocator.php',
        type: 'post',
        dataType: 'html',
        data: 'act=' + act + '&magasin_id=' + magasin_id,
        success: function (response) {

            if (response) {

                // Destruction du bloc
                if (action == 'destroy') {
                    self.slideUp('slow', function () {
                        self.remove();
                    });
                } else {
                    if (act == 'add') {
                        var libelle = '<span class="choose_fav_label"><span class="bgSprite bfr store"></span><span>' + Translator.translate('delete_as_favorite_store') + '</span></span>';
                        $('.favorite').addClass('active');
                        self.addClass('active');
                        is_favorite_elem.value = 1;
                    } else {
                        var libelle = '<span class="choose_fav_label selected"><span class="bgSprite bfr store"></span><span>' + Translator.translate('choose_as_favorite_store') + '</span></span>';
                        $('.favorite').removeClass('active');
                        self.removeClass('active');
                        is_favorite_elem.value = 0;
                    }
                    self.html(libelle);
                }
            }
        }
    });
}

/**
 * Change cart product quantity
 */
function changeQte(type) {

    var types = ['plus', 'less'],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        $(this).find('.selector').hide();
        loader.style.display = 'block';

        switch (type) {

            case 'plus':
                qtty += 1;
                break;

            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link('order_basket'),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {

                try {

                    res = JSON.parse(res);
                } catch (e) {

                    console.error('JSON ERROR: could not parse data');

                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();

                wShop.$refs.wCouponForm.reloadBloc += 1;
                //update top cart
                var response = ajax_file("ajax_show_basket.php");

                cart = Translator.translate('mon_panier');

                $('.cart_main_title.basket').html(cart + ' ' + '<span class="nb_products">(' + total + ' ' + text + ')</span>')
                document.getElementById('cart_top').innerHTML = response;

                var total = $('#cart_top .cart').attr('data-cart'),
                    text;

                if (total <= 1) {
                    text = Translator.translate('one_product');
                } else {
                    text = Translator.translate('2_product');
                }

                $('.cart_main_title.basket').html(cart + ' ' + '<span class="nb_products">(' + total + ' ' + text + ')</span>');

            },
            complete: function () {
                dragDropWishlist();
                updateCartElements();
                getWishlistProducts();
            }
        });
    }
}

/**
 * Preco lightbox cart delivery
 */
function process_type_preco() {
    var val = $(".bloc_lightbox_preco.actif").attr("data-value");

    $.ajax({
        type: "post",
        data: {type_preco: val},
        url: path_relative_root + "ajax_choose_preco.php",
        success: function (response) {
            if (response.status == "success") {
                if ($("input[name=preco_choice]").length == 0) {
                    closeMultiShad("lightbox_preco");
                }
                var index_conveyor =
                    typeof array_transporteurs_datas[1] != "undefined" ? 1 : 31;
                $.each(response.transportation, function (i, item) {
                    if (typeof array_transporteurs_datas[i] !== "undefined") {
                        array_transporteurs_datas[i]["fdp"][0] = item["unit"];
                        array_transporteurs_datas[i]["fdp"][1] = item["cent"];
                        array_transporteurs_datas[i]["fdp"]["format"] =
                            item["format"];
                        $(
                            ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .pricetag"
                        ).html(item["format"]);
                        $(
                            ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .price_tag"
                        ).html(item["format"]);
                    }
                });

                if (val == "several") {
                    $(".choix_delivery_relais").hide();
                    $(".choix_delivery_postal:first .choix_delivery").click();
                    $(".choix_delivery_postal:first .choix_livraison").attr(
                        "checked",
                        "checked"
                    );
                    $(".choix_delivery_postal:first .choix_livraison").change();
                } else {
                    $(".choix_delivery_relais").show();
                    $(".choix_delivery_relais:first").click();
                    $(".choix_delivery_relais:first .choix_livraison").attr(
                        "checked",
                        "checked"
                    );
                    $(".choix_delivery_relais:first .choix_livraison").change();
                }
                $("#tunnel_right_col #cart_total").css("visibility", "visible");
            }
        },
    });
}

function updateCartElements() {

    if ($('#cartAssociations').length > 0) {

        var cartAssociations = new Swiper('#cartAssociations', {

            slidesPerView: 2,
            grid: {
                rows: 2,
            },
            spaceBetween: 50,
            roundLengths: true,
            setWrapperSize: true,
            navigation: {
                nextEl: '.assoc-button-next',
                prevEl: '.assoc-button-prev',
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                draggable: true,
            },
        });
    }
}

var look_add = false;

function addLook(loader) {

    $(loader).fadeIn(200);

    look_add = true;

    calculTotal('slide_look_detail', true);
}

function calculTotal(id, process_look) {

    var $form = $(document.getElementById(id));
    var data = $form.serialize();

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_calcul_total.php',
        data: data,
        success: function (response) {

            try {

                response = JSON.parse(response);
            } catch (e) {

                console.error('parseJSON');

                return;
            }

            if (response.success == 'ok') {

                var datas = response.datas;
                var total = datas.total;

                $('#look_total', '#look_info').val(total);
                $('.price_total', '#look_info').html(datas.total_format);

                if (process_look) {

                    if (total > 0) {

                        if (!addToCartTmpl) {

                            addToCartTmpl = document.getElementById('modbox').innerHTML.trim();

                            Mustache.parse(addToCartTmpl);
                        }

                        buildLookAddedModbox(false);
                        processlook();
                        setTimeout(function () {

                            $('.loader').fadeOut();
                        }, 2000);
                    } else {

                        var $alertbox = $('#alertbox1');

                        res = ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_quantity');

                        html_err = '<div class="txt_alert"></div>';
                        html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="button primary">OK</a></div>';
                        if ($alertbox.length == 0) {
                            $alertbox = $('<div id="alertbox1" class="lightbox">' + html_err + '</div>');
                            $('body').append($alertbox.hide());
                        }

                        $alertbox.find('.txt_alert').html(res);
                        openMultiShad('alertbox1', 'fixed');

                        $('#bloc_btn_look').show();
                        $('#bloc_btn_loader_look').hide();

                        if ($('.loader', '#bloc_add_basket').length > 0) {

                            $('.loader', '#bloc_add_basket').hide();
                        }
                    }
                }
            }
        }
    });
}

function processlook() {

    $('.submit_panier div:first-child:not(.loader_connexion)').each(function () {

        var $elem = $(this).attr('id');
        var $id = $elem.substring($elem.lastIndexOf("_") + 1);
        var $val_item = $("#qteProd_" + $id).val();

        if ($val_item > 0) {
            $(this).click();
        }
    });
}

function showQteListe(elementID, idProduit, parentPath, suff) {
    var couleurs_id = 'couleurProd';
    var tailles_id = 'tailleProd';

    if (elementID != 'liste_qte') {
        couleurs_id += '_' + idProduit;
        tailles_id += '_' + idProduit;
    }

    var listQte = $('#' + elementID);
    var alternative_listQte = $('#liste_qte_spe');

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html('<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' + parentPath + 'img/loader.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>');

            var couleurs = (($('#' + couleurs_id).length) ? $('#' + couleurs_id).val() : 'none');
            var tailles = (($('#' + tailles_id).length) && ($('#' + tailles_id).val() != 0) ? $('#' + tailles_id).val() : 'none');
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = 'none';
            var tailles = 'none';
        }

        var response = ajax_file(parentPath + 'ajax_liste_qte.php?idprod=' + idProduit + '&idcouleur=' + couleurs + '&idtaille=' + tailles + '&produit_lot_nb=' + $("#produit_lot_nb").val() + '&suff=' + (suff || ''));

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            $('.selectStyled').each(function () {
                $(this).selectStyled();
            });

            var select_stock = $('#qteProd');
            var regexp_epuise = /Stock /;
            var txt_stock = $('#qteProd>option:selected').text();

            if (select_stock.length > 0 && txt_stock.match(regexp_epuise) != null) {

                $("#bloc_add_alert").show();
                $("#add_basket").hide();
                $("#bloc_add_basket").hide();
                $(".eclat_epuise").removeClass('cache');
                $(".delivery_date").hide();
                $(".eclat_epuise").show();
                $(".eclatProd").hide();

            } else {

                $("#bloc_add_alert").hide();
                $("#add_basket").show();
                $("#bloc_add_basket").show();
                $(".eclat_epuise").hide();
                $(".delivery_date").show();
                $(".eclatProd").show();

            }

        }

    }
}

function showQteListeGamme(elementID, idProduit, parentPath) {

    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById('liste_qte_spe_' + idProduit);

    if (listQte || alternative_listQte) {
        if (listQte) {
            if (document.getElementById('couleurProd_' + idProduit)) var couleurs = document.getElementById('couleurProd_' + idProduit).value;
            if (document.getElementById('tailleProd_' + idProduit)) var tailles = document.getElementById('tailleProd_' + idProduit).value;
            listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/loader.gif" />Chargement...</td></tr></table>';
        } else {
            var couleurs = 'none';
            var tailles = 'none';
            alternative_listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/loader.gif" />Chargement...</td></tr></table>';
        }

        var string_params = '';
        if ($("#produit_lot_nb_" + idProduit).val() != undefined)
            string_params += '&produit_lot_nb=' + $("#produit_lot_nb_" + idProduit).val();

        if ($("#qte_default_" + idProduit).val() != undefined)
            string_params += '&qte_default=' + $("#qte_default_" + idProduit).val();

        var response = ajax_file(parentPath + 'ajax_liste_qte_alerte.php?idprod=' + idProduit + '&idcouleur=' + couleurs + '&idtaille=' + tailles + string_params);

        if (response) {
            if (listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;

            $('.selectStyled').each(function () {
                $(this).selectStyled();
            });
        }
    }
}

var addToCartTmpl;

function buildItemAddedModbox(id_produit, is_not_produit) {

    var form = document.getElementById('prod_info_' + id_produit);
    var closing_method = (is_not_produit == 'true') ? 'window.location.reload()' : 'close_lightbox(\'modbox\')';
    var lot_nb = form.getValue('produit_lot_nb', parseFloat);

    if (document.getElementById('nbr_visu_0')) {

        var itm_vis = $('img', '#nbr_visu_0').attr('src').replace('/mini/', '/large/');
    }

    var itm_name = $('#itm_name', form).get(0).textContent.trim();

    if ($('#itm_feat', form).length > 0) {

        var itm_feat = $('#itm_feat', form).get(0).textContent.trim();
    }

    var itm_price = $("#prixU", form).val();

    if (lot_nb > 1) {
        itm_price = formatPrice(parseFloat(itm_price) * parseInt(lot_nb));
    }


    if (form) {
        var itm_length = parseFloat(form.elements.namedItem('qteProd').value);


        if (lot_nb > 2 || itm_length > 1) {
            itm_length += ' ' + Translator.translate('items');
        } else {

            itm_length += ' ' + Translator.translate('item');
        }


        if (itm_length == 1) {

            itm_length += ' ' + Translator.translate('added_to_cart_sn');
        } else if (form.getValue('produit_lot_nb') == '1') {

            itm_length += ' ' + Translator.translate('added_to_cart_pl');
        }

    }

    $('#shad').after($('#modbox'));

    document.getElementById('modbox').innerHTML = Mustache.render(addToCartTmpl, {
        closing_method: closing_method,
        itm_vis: itm_vis,
        itm_name: itm_name,
        itm_feat: itm_feat,
        itm_price: itm_price,
        itm_length: itm_length
    });
}

function buildLookAddedModbox(is_not_produit) {

    var form = document.getElementById('slide_look_detail');
    var closing_method = (is_not_produit == 'true') ? 'window.location.reload()' : 'close_lightbox(\'modbox\')';
    var look_total = formatPrice(parseFloat(document.getElementById('look_total').value));
    var look_items = [];

    $('fieldset', form).each(function () {

        var itm_quantity = parseFloat($('.qte_select_wrapper select', this).get(0).value);

        if (itm_quantity > 0) {

            var new_look_itm = {};

            new_look_itm.itm_visu = $('.visu_look', this).attr('src').replace('/medium/', '/mini/');
            new_look_itm.itm_name = this.getValue('itm_name');
            new_look_itm.itm_quantity = itm_quantity;
            new_look_itm.itm_total = formatPrice(this.getValue('itm_price', parseFloat) * itm_quantity);

            look_items.push(new_look_itm);
        }
    });

    $('#shad').after($('#modbox'));

    document.getElementById('modbox').innerHTML = Mustache.render(addToCartTmpl, {
        closing_method: closing_method,
        look_total: look_total,
        look_items: look_items
    });
}

function newshowmodal(id_produit, is_not_produit) {

    CartShow();
}

// Pretty straightforward: this formats a price
var formatPrice = (function () {

    var THREEDIGITS = new RegExp("\\d{3}", "g");
    var FORMAT = window.price_format_plain;

    // Splits a number into chunks of 3 digits
    function splitThousands(nmbr) {

        var f_chunk = '', chunk_length;

        nmbr = String(nmbr);

        chunk_length = nmbr.length % 3;

        if (chunk_length) {

            f_chunk = nmbr.slice(0, chunk_length);
            nmbr = nmbr.substr(chunk_length);
        }

        return f_chunk + nmbr.replace(THREEDIGITS, function (match) {

            return ' ' + match;
        });
    }

    return function (price) {
        var CURRENCY = window.siteCurrency;

        var output;

        if (price.constructor !== String) {

            price = price.toString(10);
        }

        price = price.replace('.', ',');
        price = price.split(',');

        output = FORMAT.replace('[<unit>]', splitThousands(price[0]));
        output = output.replace('[<symbol_right>]', CURRENCY.symbol_right);
        output = output.replace('[<symbol_left>]', CURRENCY.symbol_left);

        if (price[1] === undefined) {

            price[1] = '00';
        } else if (parseFloat(price[1]) < 10) {

            price[1] = '0' + price[1];
        }

        output = output.replace('[<decimal>]', price[1]);
        output = output.replace('[<code>]', CURRENCY.code);

        return output;
    };
}());

function waitingAddToBasket_legacy(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, is_lightbox_specifique) {

    var id = '';
    if (is_not_produit == true)
        id += '_' + produit_id;

    if (is_lightbox_specifique)
        add_produit_specifique(produit_id, idCouleur, idTaille);
    else
        window.setTimeout('addToBasket_legacy("' + produit_id + '", "' + suff + '", "' + panier_id + '", "' + titreObjet + '", "' + idTaille + '", "' + lastTaille + '", "' + idCouleur + '", "' + lastCouleur + '","' + toEval + '", "' + page_name + '", "' + path_web + '", ' + enable_omniture + ', ' + is_not_produit + ');', 1000);

    $("#bloc_btn_active" + id).attr('class', 'cache');
    $("#bloc_btn_loader" + id).attr('class', 'f_right');

}

function addToBasket_legacy(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit) {

    var id = '';

    if (is_not_produit == true)
        id += '_' + produit_id;

    prix_id = 'prixU';
    if (is_not_produit == true) {
        var code_color = $('#couleurUnique_' + produit_id).text();
        var nom_color = $('#couleurUnique_' + produit_id).text();
        var prix_id = 'prixU_' + produit_id;
    }

    var array_panier = newUpdatePanier_legacy(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {
        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {
            type = 'err';
        }

        omnitureEvent = '';
        var response = ajax_file(path_web + 'ajax_show_basket.php');
        $("#cart_top").html(response);
        CartShow();

        if (array_panier[1] === 0) {
            $('#nb_article').html(array_panier[1]);
            omnitureEvent = '';
        } else if (array_panier[1] == 1) {
            $('#nb_article').html(array_panier[1]);
            $('#panier').attr('href', path_web + create_link('order_basket')).attr('style', 'cursor:pointer;');

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        } else {
            $('#nb_article').html(array_panier[1]);
            $('#panier').attr('href', path_web + create_link('order_basket')).attr('style', 'cursor:pointer;');

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        newshowmodal(array_panier[1], type, path_web, is_not_produit, produit_id);

        $("#bloc_btn_active" + id).attr('class', '');
        $("#bloc_btn_loader" + id).attr('class', 'f_right cache');

        if (array_panier[1] > 0 && enable_omniture == true) {
            void (s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void (s.t());
        }
    } else {

        $("#bloc_btn_active" + id).attr('class', '');
        $("#bloc_btn_loader" + id).attr('class', 'f_right cache');

    }

    eval(toEval);

    if (array_panier != false) {
        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {
            var svn = document.getElementsByTagName("SELECT");
            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function newUpdatePanier_legacy(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur) {
    if (i != '') {
        i = '_' + i;

        prixU = $('#prixU' + i).val();
    }

    var couleurs = document.getElementById('couleurProd' + i);
    var tailles = document.getElementById('tailleProd' + i);
    var $alertbox = $('#alertbox1');
    var res = '';
    var html_err = '';
    var flagError = false;

    html_err = '<div class="txt_alert"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="button primary">OK</a></div>';
    if ($alertbox.length == 0) {
        $alertbox = $('<div id="alertbox1" class="lightbox">' + html_err + '</div>');
        $('body').append($alertbox.hide());
    }

    if (tailles && tailles.value == '') {
        res = Translator.translate('js_error_size');
        flagError = true;

    } else if (couleurs && couleurs.value == '') {
        res = Translator.translate('js_error_couleur');
        flagError = true;

    } else if (document.getElementById('qteProd' + i) && document.getElementById('qteProd' + i).value == '') {
        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        $alertbox.find('.txt_alert').html(res);
        openMultiShad('alertbox1', 'fixed');
        return false;
    } else {

        var qte = 0;

        if ($('#qteProd' + i)) {
            qte = $('#qteProd' + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.value != 'none') idCouleur = couleurs.value;
        if (tailles && tailles.value != 'none') idTaille = tailles.value;

        var array_response = new Array();
        var response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);

        if (response) {

            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match('^[0-9]')) {

                if (designation == 1) {

                    designation += ' lot de';

                } else {

                    designation += ' lots de';

                }

            }

            designation += ' ' + titreObjet;

            if (document.getElementById(idElTaille)) {

                if (document.getElementById(idElTaille).selectedIndex) {

                    //designation += ' - ' + document.getElementById(idElCouleur).text; // ne fonctionne pas
                    designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;


                } else if (lastTaille != '') {

                    designation += ' - Taille ' + lastTaille;

                }

            }

            if (document.getElementById(idElCouleur)) {

                if (document.getElementById(idElCouleur).selectedIndex) {

                    //designation += ' - ' + document.getElementById(idElCouleur).text; // ne fonctionne pas
                    designation += ' - ' + document.getElementById(idElCouleur).options[document.getElementById(idElCouleur).selectedIndex].text;

                } else if (lastCouleur != '') {

                    designation += ' - ' + lastCouleur;

                }

            }

            array_response[0] = designation;

        }

        return array_response;
    }
}

function verif_telephone(elementID, fieldname, is_mobile) {

    // type 0 -> fixe (01)
    // type 1 -> mobile (06)
    // type 3 -> fixe (08)
    // type 6 -> fixe (09)
    // Le plugin refuse les 07, donc si il s'agit d'un 07, on ne passe pas par le plugin ( intlTelInput("isValidNumber") ).

    var objElementHidden = $('#' + elementID + 'Hidden');
    var objElement = $('#' + elementID);
    var erreurText = document.getElementById('erreur_msg');

    var inputMobile = '';
    var inputTel = '';
    if ($('#mobileHidden').length > 0) { //tunnel
        inputMobile = $('#telephoneHidden');
        inputTel = $('#mobileHidden');
    } else if ($('#clientMobileHidden').length > 0) { //mes info
        inputMobile = $('#clientMobileHidden');
        inputTel = $('#clientTelHidden');
    } else { // newsletter
        inputMobile = inputTel = objElementHidden;
    }

    var value = objElement.intlTelInput("getNumber");
    var type = objElement.intlTelInput("getNumberType");

    if ($.trim(objElement.val()).length > 0) {
        if (value.length == 12 && value.substr(1, 3) == '337') {
            inputMobile.val(value);
        } else {
            if (objElement.intlTelInput("isValidNumber") !== false && (type === 0 || type === 3 || type === 6)) {
                inputTel.val(value);
            } else if (objElement.intlTelInput("isValidNumber") !== false && type === 1) {
                inputMobile.val(value);
            } else if (objElement.intlTelInput("isValidNumber") !== false && type === 2) {
                inputTel.val(value);
                inputMobile.val(value);
            } else {
                objElement.addClass('inputErr');
                return false;
            }
        }
        return true
    } else {
        objElement.addClass('inputErr');
        return false;
    }
}

/**
 * Add class on module tags to improve rollover positioning
 */
function tagPosition() {

    var centerPos = window.innerWidth / 2;
    var middlePos = window.innerHeight / 2;

    $('.tag').each(function () {

        var tagCenterPos = $(this).offset().left;
        var tagMiddlePos = $(this).offset().top - window.pageYOffset;

        if (tagCenterPos >= centerPos) {

            $(this).removeClass('leftPos');
            $(this).addClass('rightPos');
        } else {

            $(this).removeClass('rightPos');
            $(this).addClass('leftPos');
        }

        if (tagMiddlePos >= middlePos) {

            $(this).removeClass('topPos');
            $(this).addClass('bottomPos');
        } else {

            $(this).removeClass('bottomPos');
            $(this).addClass('topPos');
        }
    });
}

function tagPositionForHomeModule() {
    if ( $( '.image-text-cta' ).length ) {
        var imgWrapper = $( '.image-text-cta' ).find( '.imgWrapper' );

        var centerPos = imgWrapper.width() / 2;
        var middlePos = imgWrapper.height() / 2;

        imgWrapper.find( '.tag' ).each( function () {

            var tagCenterPos = ( 7 + parseInt( $( this ).css( "left" ) ) );
            var tagMiddlePos = ( 7 + parseInt( $( this ).css( "top" ) ) );

            if ( tagCenterPos >= centerPos ) {

                $( this ).removeClass( 'leftPos' );
                $( this ).addClass( 'rightPos' );
            } else {

                $( this ).removeClass( 'rightPos' );
                $( this ).addClass( 'leftPos' );
            }

            if ( tagMiddlePos >= middlePos ) {

                $( this ).removeClass( 'topPos' );
                $( this ).addClass( 'bottomPos' );
            } else {

                $( this ).removeClass( 'bottomPos' );
                $( this ).addClass( 'topPos' );
            }
        } );
    }
}

$(function () {
    if ($('.instaSlider').length) {

        new Swiper('.instaSlider', {

            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: 5,
            slidesPerGroup: 5,
            spaceBetween: 25,
            navigation: {
                prevEl: ".instaSliderPrev",
                nextEl: ".instaSliderNext",
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                draggable: true,
            },
            breakpoints: {}
        });
    }

    /**
     * Switch to tablet mode depending on resolution and resized window
     */
    $(window).on('load resize', function () {
        tabletModeMenu();

        if ($('.tooltip-module').length) {

            tagPosition();
        }

        if ( $( '.image-text-cta' ).length ) {
            resizeHomeModuleRollover();
        }
    });

    /**
     * Display shad on menu rollover + slider initialization if available
     */
    $('.menu_itm_hover').not('.hasOnlyOneCategory').each(function () {

        var shadMenu = $('#shad_menu');

        $(this).on('mouseenter', function () {

            shadMenu.addClass('actif');

            if ($(this).hasClass('hasSlider')) {

                $('.mainMenuSlider').removeClass('displayed');
                const mainMenuSlider = new Swiper('.mainMenuSlider', {

                    roundLengths: true,
                    setWrapperSize: true,
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 18,
                    navigation: {
                        prevEl: ".menuSliderPrev",
                        nextEl: ".menuSliderNext",
                    },
                    breakpoints: {
                        1100: {
                            slidesPerView: 4,
                            slidesPerGroup: 4
                        },
                        1280: {
                            slidesPerView: 5,
                            slidesPerGroup: 5
                        },
                        1400: {
                            slidesPerView: 6,
                            slidesPerGroup: 6
                        }
                    }
                });

                setTimeout(function () {

                    $('.mainMenuSlider').addClass('displayed');
                }, 150);
            }
        });

        $(this).on('mouseleave', function () {

            shadMenu.removeClass('actif');
        });
    });

    /**
     * Search events
     */
    var search_home = document.getElementById('search_home');
    var shad_menu = document.getElementById('shad_menu');

    if (document.getElementById('search_home') !== null) {
        search_home.addEventListener('click', function () {

            shad_menu.classList.add('actif');
            shad_menu.addEventListener('click', function () {

                closeRecherche();
            });
        });

        $('#search_home').keypress(function (e) {
            var key = e.which;

            if (key == 13 && $('#search_home').val().length >= 3) {
                closeRecherche();
            }
        });

        $('.results_list li').each(function () {

            $(this).on('click touch', function () {

                closeRecherche();
            })
        });
    }

    /**
     * Header rollovers => Wishlist / Account / Basket
     */
    $(document).on('mouseenter', '.headerRollover', function() {
        var $this = $(this);

        if ($this.hasClass('hasItem') || $this.hasClass('is_logged')) {
          var shadMenu = $('#shad_menu');
          var mainMenu = $('#wrapper_top_menu');

          mainMenu.addClass('shaded');
          shadMenu.addClass('actif');
          $this.addClass('hover');
          $('.cart_product_listing').overlayScrollbars({
            overflowBehavior: {
              x: 'hidden',
            },
          });
        }
      }).on('mouseleave', '.headerRollover', function() {
        var $this = $(this);
        var shadMenu = $('#shad_menu');
        var mainMenu = $('#wrapper_top_menu');

        shadMenu.removeClass('actif');
        mainMenu.removeClass('shaded');
        $this.removeClass('hover');
      });

    /**
     * Back to top event
     */
    backToTop = function () {

        var scrollTrigger = 200;
        var scrollTop = $(window).scrollTop();
        var copyrightHeight = $('#copyright').innerHeight();
        var copyrightOffset = $('#copyright').offset();

        if (scrollTop > scrollTrigger && scrollTop < copyrightOffset.top - copyrightHeight) {

            $('.back_to_top').addClass('show');
        } else {

            $('.back_to_top').removeClass('show');
        }
    };

    backToTop();

    $(window).on('scroll', function () {

        backToTop();

        if ($('.tooltip-module').length) {

            tagPosition();
        }
    });

    $('.back_to_top').on('click', function (e) {

        e.preventDefault();
        $('html, body').animate({

            scrollTop: 0
        }, 700);
    });

    /**
     * Cover module
     */
    if ($('.sliderMainHome').length) {

        new Swiper('.sliderMainHome', {

            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: 1,
            pagination: {
                el: '.cover-pagination',
                clickable: true,
            },
        });
    }

    /**
     * TG home module
     */
    if ($('.swiperVf').length) {

        var swiperVf = new Swiper('.swiperVf', {

            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 13,
            roundLengths: true,
            setWrapperSize: true,
            navigation: {
                nextEl: '.tg-home-next',
                prevEl: '.tg-home-prev',
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                draggable: true,
            },
            breakpoints: {
                1200: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            },
        });
    }

    /**
     * Category visual slider
     */
    if ($('.bloc_sub_categ').length) {

        var subCategSlider = new Swiper('.bloc_sub_categ', {

            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: 3,
            spaceBetween: 44,
            navigation: {
                nextEl: ".button-sub-categ-next",
                prevEl: ".button-sub-categ-prev",
            },
            breakpoints: {
                768: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
                1280: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
                1600: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
                1650: {
                    slidesPerView: 6,
                    slidesPerGroup: 6,
                },
            },
        });
    }

    /**
     * Category SEO txt read more
     */
    if ($('.txt.trimed').length) {

        var trimedTxt = $('.txt.trimed');
        var fullTxt = $('.txt.full');
        var read_more = $('.read_more');
        var read_less = $('.read_less');

        read_more.on('click', function () {
            trimedTxt.hide();
            fullTxt.show();
            trimedTxt.addClass('cache');
            fullTxt.removeClass('cache');
        });

        read_less.on('click', function () {
            trimedTxt.show();
            fullTxt.hide();
            trimedTxt.removeClass('cache');
            fullTxt.addClass('cache');
        });
    }

    goToDesc();

    /**
     * Anchor to netreviews tab
     */
    if ($('.product_page').length && $('.avis_alma .item_ratings').length) {

        $(document).on("click", ".avis_alma .item_ratings", function() {
            $('html, body').animate({

                scrollTop: $('#w-netreviews-comments').offset().top - 200
            }, 600,);
        });
    }

    /**
     * Toggle product features
     */
    refreshToggleProduct();

    initFpSliders();
    window.addEventListener("resize", initFpSliders);

    /**
     * Inspiration category slider
     */
    if ($('.slider_push_down').length) {

        var slider_push_down = new Swiper('.slider_push_down', {
            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 18,
            navigation: {
                prevEl: ".slider_push_down_prev",
                nextEl: ".slider_push_down_next",
            },
            breakpoints: {
                1100: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                1280: {
                    slidesPerView: 5,
                    slidesPerGroup: 5
                },
                1400: {
                    slidesPerView: 6,
                    slidesPerGroup: 6
                }
            }
        });
    }

    /**
     * Toggle store description if too long
     */
    if ($('#descrip_store').length && $('#descrip_store').text().length > 900) {

        var description = $('#descrip_store');
        var trigger = $('#descrip_store .trigger');
        var originH = description.height();

        description.css('height', '284px');
        trigger.show();

        trigger.on('click touch', function () {

            if ($(this).hasClass('open')) {

                description.css('height', '284px');
                trigger.removeClass('open');
                trigger.text(Translator.translate('read_more'));
            } else {

                description.css('height', (originH + 30) + 'px');
                trigger.addClass('open');
                trigger.text(Translator.translate('read_less'));
            }
        });
    }

    /**
     * Store pictures slider if more than 1
     */
    if ($('#store_slider .swiper-slide').length > 1) {

        var swiperStore = new Swiper("#store_slider", {
            autoplay: {
                delay: 3000
            },
            speed: 1500,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }
        });
    }

    /**
     * Switch preco txt position
     */
    if ($('.precoBasket').length) {

        $('.precoBasket').insertBefore('.cart_main_title');
    }

    /**
     * Cart product associations
     */
    if ($('#cartAssociations').length > 0) {

        var cartAssociations = new Swiper('#cartAssociations', {

            slidesPerView: 1,
            grid: {
                rows: 2,
            },
            spaceBetween: 20,
            breakpoints: {
                1114: {
                    slidesPerView: 2,
                    grid: {
                        rows: 2,
                    },
                    spaceBetween: 20,
                },
                1300: {
                    slidesPerView: 2,
                    grid: {
                        rows: 2,
                    },
                    spaceBetween: 50,
                },
            },
            roundLengths: true,
            setWrapperSize: true,
            navigation: {
                nextEl: '.assoc-button-next',
                prevEl: '.assoc-button-prev',
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                draggable: true,
            },
        });
    }

    /**
     * Toggle opening hours for stores in cart delivery
     */
    if ($(".bloc_horaires .title_horraire").length) {

        $(".bloc_horaires .title_horraire").click(function () {

            $('.bloc_horaires .horaire').slideToggle();
            $(this).toggleClass('open');
        });
    }

    if ($('.tooltip-module').length) {

        tagPosition();
    }

    if ($('.image-text-cta').find('.tags-').length) {

        tagPositionForHomeModule();
    }

    if ($('.w-contact-form').length) {

        var placeholderTxt = $('.placeholderTxt');
        var inputFields = $('.w-contact-form input');

        if (placeholderTxt.hasClass('inUse')) {
            $(this).prev(inputFields).focus();
        }

        placeholderTxt.on('click touch', function () {

            $(this).addClass('inUse');
            $(this).prev(inputFields).focus();

            if ($(this).prev().children(inputFields).hasClass('clientPhone')) {

                $('.clientPhone').focus();
            }
        });

        inputFields.on('focus', function () {

            $(this).addClass('inUse');
            $(this).next(placeholderTxt).addClass('inUse');

            if ($(this).hasClass('clientPhone')) {

                $(this).parent().next(placeholderTxt).addClass('inUse');
            }
        });

        inputFields.on('blur', function () {

            if ($(this).val() === '') {

                $(this).removeClass('inUse');
                $(this).next(placeholderTxt).removeClass('inUse');

                if ($(this).hasClass('clientPhone')) {

                    $(this).parent().next(placeholderTxt).removeClass('inUse');
                }
            }
        });
    }

    /**
     * Slider Qui sommes-nous
     */
    if ($('.companySlider').length) {

        var companySlider = new Swiper(".companySlider", {

            roundLengths: true,
            slidesPerView: 'auto',
        });
    }

    /**
     * Slider Good Living Project
     */
    if ($('.pilersSlider').length) {

        var pilersSlider = new Swiper(".pilersSlider", {

            roundLengths: true,
            slidesPerView: 'auto',
        });
    }

    /**
     * Toggle materials guide-d-entretien-des-produits.html
     */
    $('.discover-wrapper').on('click touch', function(){

        var idMaterial = $($(this).parent('.materials-wrapper')).attr("id") ;
        var target = '#'+idMaterial

        if (!$(target).hasClass('active')) {

            $(target).addClass('active');
            $(target).children('.materials-wrapper-content').css('display','flex');
            $(target).children('.material-preview').css('display','none');
            $(this).children('.less').css('display','block');
            $(this).children('.more').css('display','none');
        } else {

            $(target).removeClass('active');
            $(target).children('.materials-wrapper-content').css('display','none');
            $(target).children('.material-preview').css('display','block');
            $(this).children('.less').css('display','none');
            $(this).children('.more').css('display','block');
        }
    });

    /* FLOWBOX */
    setTimeout( function () {
        /* Add title and button to FlOWBOX on homepage and product page */
        if($('body.homepage').length || $('body.product_page').length) {
            /* Check repetitively for the existance of #flowbox-container every 300ms */
            let intervalID = setInterval(addTitleToFlowbox, 300);
            let counter = 0;
            const maxRepetitions = 17;

            function addTitleToFlowbox() {
                if ($('#flowbox-container').length && !$('#flowbox-container').is(':empty')) {
                    let createDivFlowboxTitle = '<div id="banner_flowbox_title"><div class="ctn"><p class="banner_h1">' + Translator.translate('banner_flowbox_title') + '</p><p class="banner_h2">' + Translator.translate('banner_flowbox_subtitle') + '</p></div></div>';
                    let btnFlowbox = '<a href="' + path_relative_root + create_link('inspiration_flowbox') + '" class="button primary flowboxBtn"><span>' + Translator.translate('flowbox_inspi_button') + '</span></a>';

                    $(createDivFlowboxTitle).insertBefore("#flowbox-container");
                    $(btnFlowbox).insertAfter("#flowbox-container");

                    /* #flowbox-container found, stop repetitions */
                    clearInterval(intervalID);
                } else if (counter >= maxRepetitions) {
                    /* Stop repetitions after 17 times */
                    clearInterval(intervalID);
                }

                ++counter;
            }
        }

        /* Initiate FLOWBOX and Create CTA (filter by tags) only on page inspiration.php */
        if ( $( 'body.inspiration_flowbox' ).length ) {
            /* Create Flowbox container */
            let flowboxContainer = '<div id="inspiration-flowbox-container"></div>';
            $( "#copyright" ).before( flowboxContainer );

            /* Create Title */
            let createDivFlowboxTitle = '<div id="banner_flowbox_title"><div class="ctn"><p class="banner_h1">' + Translator.translate( 'banner_flowbox_title' ) + '</p><p class="banner_h2">' + Translator.translate( 'banner_flowbox_subtitle' ) + '</p></div></div>';
            let btnFlowbox = '<a href="' + path_relative_root + create_link( 'inspiration_flowbox' ) + '" class="button primary flowboxBtn"><span>' + Translator.translate( 'flowbox_inspi_button' ) + '</span></a>';

            $( createDivFlowboxTitle ).insertBefore( "#inspiration-flowbox-container" );
            $( btnFlowbox ).insertAfter( "#inspiration-flowbox-container" );

            /*
                Object of CTA msgid and respective tag, this object will render the CTAs.
                To modify CTA content, modify the msgstr of these msgid in .po files.
                To modify tag used to filter, modify the value directly in the object below.
            */
            let cta_name_tag = {
                'cta_all':'',
                'cta_salon': 'salon',
                'cta_salleamanger': 'salle à manger',
                'cta_chambre': 'chambre',
                'cta_entree': 'entree et couloir',
                'cta_chambre_enfant': 'chambre enfant',
                'cta_bureau': 'bureau'
            };

            /* Create wrapper for the CTAs */
            let flowboxCtaWrapper = '<div id="banner_flowbox_cta"></div>';
            $( flowboxCtaWrapper ).insertAfter( '#banner_flowbox_title' );

            /* Loop over cta_name_tag object to create CTA and append them to the wrapper */
            $.each(cta_name_tag, function(name, tag) {
                let button = '<button class="flowbox_btn_cta" data-tag="' + tag + '"><span class="cta_name">' + Translator.translate(name) + '</span></button>';

                $( '#banner_flowbox_cta' ).append( button );
            } );

            /* Create loader */
            let loader = '<div id="flowbox_loader"></div>';
            $( '#banner_flowbox_cta' ).append( loader );

            /* Add class 'active' to the 'ALL' CTA */
            $( '.flowbox_btn_cta:first-child' ).addClass( 'active' );

            /* Initialise Flowbox */
            let flowboxLocale = Translator.translate( 'flowbox_locale' );

            window.flowbox('init', {
                container: '#inspiration-flowbox-container',
                key: 'dKCLq26YSlORATW9A6ecjw', /* ATMOSPHERA Flowkey Page Communauté */
                locale: flowboxLocale, /* language-COUNTRY */
                tags: [],
                tagsOperator: "any"
            } );

            /* Function to test if FlowBox has initiated and hide loader */
            function hideLoader() {
                setTimeout( function () {
                    if ( $( '.bzfy-o-layout__item' ).length ) {
                        $( '#flowbox_loader' ).fadeOut( 500 );
                        return false;
                    }

                    setTimeout( function () {
                        if ( $( '.bzfy-o-layout__item' ).length ) {
                            $( '#flowbox_loader' ).fadeOut( 500 );
                            return false;
                        }

                        setTimeout( function () {
                            if ( $( '.bzfy-o-layout__item' ).length ) {
                                $( '#flowbox_loader' ).fadeOut( 500 );
                                return false;
                            }

                            setTimeout( function () {
                                $( '#flowbox_loader' ).fadeOut( 500 );
                            }, 1500 );
                        }, 500 );
                    }, 500 );
                }, 500 );
            };

            /* Hide initial loader */
            hideLoader();

            /* Function to update Flowbox on click of CTA */
            function updateFlow (tag, tagsOperator) {
                window.flowbox('update', {
                    key: 'dKCLq26YSlORATW9A6ecjw', /* ATMOSPHERA Flowkey Page Communauté */
                    tags: [tag],
                    tagsOperator: tagsOperator
                });
            };

            /* CTA event listener */
            $( 'button.flowbox_btn_cta' ).on( 'click', function () {
                $( '#flowbox_loader' ).fadeIn(500);

                if ( this.dataset.tag == "" ) {
                    /* Reset FlowBox */
                    updateFlow( [], "any" );
                } else {
                    /* Update Flowbox with selected tag */
                    updateFlow( this.dataset.tag, "all" );
                }

                /* Change which CTA has class 'active' for future styling purposes */
                $( 'button.flowbox_btn_cta' ).removeClass( 'active' );
                $( this ).addClass( 'active' );

                /* Hide loader */
                hideLoader();
            });
        }
    }, 300 );

    /*
     * Fade in on login page to avoid an asynchron display between texts and forms
     */
    if ($('.login_wrapper').length) {

        setTimeout( function () {

            $('.login_wrapper').addClass('appear');
        }, 250);
    }

    $( '.checkout.in_tunnel .wrapper_bloc_banque.credit_card' ).append( "<p class='credit_subtitle'>" + Translator.translate( 'by_clicking' ) + "</p>" );

    $('.wrapper_bloc_banque').on('click', function (e) {
        // validate seperatly alias bank wrapper to handle actif class + payment and prevent direct click validation
        if (!$(this).hasClass('actif')) {
            $('.wrapper_bloc_banque.actif').removeClass('actif');

            // Alias wrapper special case, delay it so the payment doesn't triggers
            if ($(this).hasClass("alias_list_wrapper")) {
                // Store $(this) in a variable to prevent losing scope
                var alias_bloc = $(this);

                setTimeout(function() {
                    // Normally add actif class on bloc but with a short delay
                    alias_bloc.addClass('actif');
                }, 300);
            } else {
                // Normally add actif class on bloc
                $(this).addClass('actif');
            }
        } else {
            if ($(this).hasClass('credit_card')) {
                $('.wrapper_bloc_banque.actif').addClass('actif_flexco');
            }
        }
    });

    if ($('.toggleBatchCharactWrapper').length) {
        $('#toggleBatchCharact').on('click', function () {
            if ($(this).hasClass('actif')) {
                $(this).removeClass('actif');
                $(this).closest('.toggleBatchCharactWrapper').removeClass('actif');
                $('.toggleCarac').removeClass('actif');
                $('.content').removeClass('actif');
            } else {
                $(this).addClass('actif');
                $(this).closest('.toggleBatchCharactWrapper').addClass('actif');
            }
        });

        setTimeout(function () {
            $('.toggleBatchContent').overlayScrollbars({
                overflowBehavior: {
                    x: 'hidden',
                },
            });
        }, 250);
    }

    // *********Verification for International & FR mobile phone number on Formulaire de Contact Error Message********* \\
    if ( $( "body.satellite_store_details #content_pdvBox_new" ).length || $("body.product_page #content_pdvBox_new").length ) {
        var el = $("#content_pdvBox_new .w-contact-form");

        if(!checkContactFormOnBlur(el)) {
            return false;
        }

        el.find(".btn_form_contact").click(function() {
            return checkContactFormOnClick (el);
        });
    }

    // On ajoute la classe "w-nonempty" dans le champ email si c'est pas présents au chargement et que le champs n'est pas vide
    if ($('.w-form').length) {
        $('.w-form').each( function () {
            var inputParent = $(this).find('.w-email-input');
            var input = inputParent.find('input.w-input-element');

            if ((input.val() !== '' || input.val() !== undefined) && !inputParent.hasClass('w-nonempty')) {
                inputParent.addClass('w-nonempty');
            }
        });
    }

    if ($('.desc_pannel:has(.toggleBatchCharactWrapper) #toggleSpecs').length) {
        var item_caracteristiques_2 = Translator.translate('item_caracteristiques_2');

        $(".desc_pannel #toggleSpecs").html(item_caracteristiques_2);
        $(".desc_pannel #toggleSpecs").show();
    }

    // Enable script for carac pic zoom on click
    displayCaracPic();

    //change txt livraison reassurance FP
    changeTextRessuranceFp();
});

/*
 * Alert stock send email
 */
function sendMailAlertStock(type_alert, form, itm_rr_id) {

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var email = $('#mail_alerte_stock' + elem).val(),
        produit_id = ($('#produit_principal' + elem).val()) ? $('#produit_principal' + elem).val() : $('#produit_principal').val(),
        taille_id = $("#tailleProd" + elem).val() ? $("#tailleProd" + elem).val() : $("#tailleProd").val(),
        couleur_id = $("#couleurProd" + elem).val() ? $("#couleurProd" + elem).val() : $("#couleurProd").val();

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();
    verif_mail = mail_value.match(mailformat);

    if (email == '' || !verif_mail) {

        $('#mail_alerte_stock' + elem ).addClass('inputErr');
        if($('#mail_alerte_stock' + elem ).parents('#bloc_add_alert_form')) {
            $('#mail_alerte_stock' + elem ).parents('#bloc_add_alert_form').addClass('inputErr');
            $('#mail_alerte_stock' + elem ).parents('.bloc_add_alert_form').addClass('inputErr');
        }

    } else {

        $('#mail_alerte_stock' + elem).removeClass('inputErr');
        if($('#mail_alerte_stock' + elem ).parents('#bloc_add_alert_form').hasClass('inputErr')) {
            $('#mail_alerte_stock' + elem ).parents('#bloc_add_alert_form').removeClass('inputErr');
            $('#mail_alerte_stock' + elem ).parents('.bloc_add_alert_form').removeClass('inputErr');
        }

        $.ajax({
            type: 'post',
            url: path_relative_root + 'ajax_email_is_optin.php',
            data: {
                email: email
            },
            success: function (is_optin) {
                $.ajax({
                    type: 'post',
                    url: path_relative_root + 'ajax_mail_alert_stock.php',
                    data: {
                        email: email,
                        produit_id: produit_id,
                        taille_id: taille_id,
                        couleur_id: couleur_id,
                        type_alert: type_alert,
                        newsletter_optin: is_optin
                    },
                    success: function (response) {
                        // Show newsletter sign-up lightbox
                        showNewsletterRegistrationLightbox(mail_value, is_optin);
                        $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                        $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                        $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                        $('.bloc_add_alert' + elem + ' a.alert_return').click();

                    },
                    fail: function () {
                        $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                        $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                        $('.bloc_add_alert' + elem + ' a.alert_return').click();
                    }
                });
            }
        });
    }
}

// Called upon size change in product page
function onItemSizeChange(form, is_regroup, itm_rr_id, change_size = true) {

    var control;
    var isWishList = $('#wishlist').length > 0;
    var isAchatExpressV2 = ($('#is_achat_express_v2').val() == 1 && !isWishList);
    var productId = form.getValue('produit_id');
    var productRef = form.getValue('produit_ref');
    control = form.elements.namedItem('tailleProd');
    control.value = this.value.trim();
    var from = ($('#is_from_lookbook').val() !== undefined) ? 'lookbook' : 'product';
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (isAchatExpressV2) {
        if (this.checked) {

            if ($('#sizeerror').is(':visible')) {
                $('#sizeerror').slideUp();
            }

            loadProduct.call(form, productId, 'taille', from, itm_rr_id, 'sizeChange');

            // Depending on the color we see if the product is available, do this only in product page
            // We check if the product is already in the wishlist
            checkProductInWishlist(productId, productRef, true);
        }
    } else {

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }
        if (is_regroup) {
            loadProduct.call(form, productId, 'taille', from, itm_rr_id, 'sizeChange');
        }
    }

    if (change_size && window.dataLayer !== undefined) {
        window.dataLayer.push({
            'event': 'changeSize',
            'produit': {
                'size_value': ($(this).attr('data-nom')),
            }
        });
        if (window.dataLayer[0].product) {
            window.dataLayer[0].product.size = ($(this).attr('data-nom'));
        }
    }
}

/**
 * Display lightbox contact store
 */
function showLightboxContactStore() {

    var shad = document.getElementById('shad');
    var modbox = document.getElementById('pdvBox');

    $('html, body').animate({
        scrollTop: 0
    }, 500);

    $(modbox).addClass('actif');
    $(shad).addClass('actif');
    $(shad).on('click', closeLightboxContactStore);
    $('#content_pdvBox').show();

    setTimeout(function () {

        $('#numTelephone').intlTelInput();
    }, 400);
}

/**
 * Close lightbox contact store
 */
function closeLightboxContactStore() {
    var shad = document.getElementById('shad');
    var modbox = document.getElementById('pdvBox');

    $(modbox).removeClass('actif');
    $(shad).removeClass('actif');
}

/**
 * Send request contact to store
 */
function sendFormContactByStore(formulaire) {
    var optin_g = ($("input[name='optin']:checked").val());
    var optin_sms = ($("input[name='optin_sms']:checked").val());
    var optin_partners = ($("input[name='optin_partners']:checked").val());

    var data = {
        'produit_id': formulaire.produit_id.value,
        'produit_url': formulaire.produit_url.value,
        'client_nom': formulaire.nom.value,
        'client_prenom': formulaire.prenom.value,
        'client_email': formulaire.email.value,
        'client_telephone': formulaire.tel.value,
        'client_message': formulaire.message.value,
        'magasin_id': formulaire.magasin_id.value,
        'optin_general': optin_g,
        'optin_sms': optin_sms,
        'optin_partners': optin_partners
    };

    $.ajax({
        url: path_relative_root + create_link('ajax_contactbystore'),
        type: 'post',
        data: data,
        async: false,
        success: function (res) {
            try {
                $('#content_pdvBox_new').html(JSON.parse(res));
                $('#pdvBox').addClass('thanks');
            } catch (e) {
            }
        }
    });
    return false; //prevent submit
}

/**
 * Display lightbox Newsletter registration
 */
function showNewsletterRegistrationLightbox(email, is_optin) {

    var shad = document.getElementById('shad');
    var modbox = document.getElementById('lightbox_alerte_stock');

    $(modbox).addClass('actif');
    $(shad).addClass('actif');
    $(shad).on('click', closeNewsletterRegistrationLightbox);
    $('#content_alerte_stock_nl').show();

    // set value mail in input hidden
    $('#newsletter_email').val(email);
    $("#content_alerte_stock_nl .input_container.bar_top").show();
    $( "#error_newsletter" ).hide();
    $( "#error_email" ).hide();
    $("#content_alerte_stock_nl input[name='optin']").prop('checked', false);
    if (is_optin == "1") {
        $("#content_alerte_stock_nl .btn_newsletter_subscription_confirmation").hide();
        $("#content_alerte_stock_nl .close_popup").show();
        $("#content_alerte_stock_nl .container_optin_offre").hide();
    } else {
        $("#content_alerte_stock_nl .btn_container").show();
        $("#content_alerte_stock_nl .btn_newsletter_subscription_confirmation").show();
        $("#content_alerte_stock_nl .close_popup").hide();
        $("#content_alerte_stock_nl .container_optin_offre").show();
    }
}

/**
 * Sumit form newsletter
 **/
function submitFormNewsletter() {
    var optin = $("#wrapper_optin input[name='optin']:checked").val();

    if (typeof optin !== "undefined") {
        if (optin == 1) {
            $.ajax({
                url: path_relative_root + 'ajax_newsletter_email.php',
                type: 'post',
                data: {
                    email: $('#newsletter_email').val()
                },
                cache: false,
                success: function (res) {
                    var resultat = JSON.parse(res);
                    if (resultat.valid == false) {
                        $( '#error_email' ).addClass( 'valid' );
                        $( '#error_email' ).css('display', 'block' );
                        $('.input_container.bar_top').css('display', 'none');
                        $('.btn_container').css('display', 'none');
                        $('.close_popup').css('display', 'block');
                    } else {
                        $('#error_newsletter').removeClass('error');
                        $('#error_newsletter').addClass('valid');
                        $('#error_newsletter').html(translate(resultat.message));
                        $('.input_container.bar_top').css('display', 'none');
                        $('.btn_container').css('display', 'none');
                        $('.close_popup').css('display', 'block');
                        $('#error_newsletter').css('display', 'block');
                    }
                }
            });
        } else {
            closeNewsletterRegistrationLightbox();
        }
    } else {
        $( '.w-radio-group' ).addClass( 'w-has-error' );
    }
    return false;
}

/**
 * Close lightbox Newsletter registration
 */
function closeNewsletterRegistrationLightbox() {
    var shad = document.getElementById('shad');
    var modbox = document.getElementById('lightbox_alerte_stock');

    $(modbox).removeClass('actif');
    $(shad).removeClass('actif');
    $("#bloc_add_alert_form").hide();
    $( "#btn_alert_stock" ).show();
    $( '.w-radio-group' ).removeClass( 'w-has-error' );
}

function changeVisu(field, path) {

    var new_img;

    field = document.getElementById(field);

    if (field && field.parentElement) {

        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener('load', function () {

            this.alt = field.alt;

            if (field.parentElement) {

                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.onclick = function () {
            zoomImg();
        }

        new_img.src = path;
        $("#zoom_box_wrapper #img_large").attr("src", path);
    }

    // Simpler way doesn't seem to work well with lazyloader
    // return document.getElementById(field).src = path;
    var productSwiper = new Swiper(".productSwiper:not(.w-slider-container)", {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: '.rollover_rayonsNext',
            prevEl: '.rollover_rayonsPrev',
        },
        preloadImages: false,
        lazy: isLazyLoad,
        loadPrevNext: true,
        loadOnTransitionStart: true
    });
}

function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;
        var has_stock = false;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('[name="itm_size"]').filter('[value="' + size_id + '"]');
                is_disabled = !sizes_tab[size_id];
                if (!is_disabled) {
                    size_box.removeClass('disabled');
                    has_stock = true;
                } else {
                    size_box.addClass('disabled');
                }
            }
        }

        if (has_stock) {
            $("#btn_add_cart").show();
            $('.eclat_nouveaute').hide();
            $(".eclat_epuise").hide();
        } else {
            if ($('#case_ajax') !== 'undefined') {
                if ($('#case_ajax').val() == '5' || $('#case_ajax').val() == '6' || $('#case_ajax').val() == '7') {
                    $(".eclat_nouveaute").removeClass('cache').show();
                    $(".eclat_epuise").hide();
                    $(".eclatProd").show().removeClass('cache');

                    $("#btn_alert_stock").removeClass('cache').show();
                    $("#btn_add_cart").hide();
                } else if ($('#case_ajax').val() == '8') {
                    $(".eclat_epuise").removeClass('cache').show();
                    $(".eclat_nouveaute").hide();
                    $(".eclatProd").hide();

                    $("#btn_add_cart").hide();
                    $("#btn_alert_stock").hide();
                } else { // Case 1 / 2 / 3 / 4
                    $(".eclat_epuise").hide();
                    $(".eclat_nouveaute").hide();
                    $(".eclatProd").show().removeClass('cache');

                    $("#btn_add_cart").removeClass('cache').show();
                    $("#btn_alert_stock").hide();
                }
            }
        }
    }
}

/**
 * Refresh toogle event for product
 */
function refreshToggleProduct() {
    if ($('.toggleCarac').length) {
        $('.toggleCarac').on('click', function () {

            if ($(this).hasClass('actif')) {
                $(this).removeClass('actif');
                $(this).next('.content').removeClass('actif');
            } else {
                $(this).addClass('actif');
                $(this).next('.content').addClass('actif');
            }

            $('#toggleBatchCharact, .toggleBatchCharactWrapper').removeClass('actif');
        });
    }
}

function changeLocale(locale, uri, site) {
    $.ajax({
        url: path_relative_root + 'ajax_get_uri_by_lang.php',
        type: 'post',
        data: {
            lang: locale,
            url: uri,
            site: site
        },
        success: function (data) {
            if (data != "") {
                var force = "/";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        }
    });
}

function verifPartnerStock() {

    var data = {}
    data.product_id = $('#produit_id').val();
    data.color_id = $('#couleurProd').val();
    data.size_id = $('#tailleProd').val();
    data.product_refext = $('#produit_refext').val();

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_product_partner.php',
        data: data,
        dataType: 'json',
        success: function (response) {
            if (response.success) {
                $('.partner_container').remove();
                $('#en_ligne').css('display', 'inline-block');
                $(response.datas).insertAfter(".ligne_container .online_intro");
            }
        }
    });
}

$.fn.selectChangeAdr = function() {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr('id');
    var data = {};
    var $changeContainer = $('.wrapper_form_adresse', $obj.closest('.adresse_change'));
    data.act = 'ajout';
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = 'ajax';

    if (obj_val != '') {

        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = 'modif';
        }
        if ($changeContainer.is('.show')) {
            $changeContainer.removeClass('show').slideUp().html('');
        }
        $.ajax({
            type: 'get',
            data: data,
            url: path_relative_root + 'livraison-ajax.php',
            success: function(response) {
                var $responce = $(response);
                var min_form = $('.min_form', $responce).html();
                var $form = $('#adresseForm', $responce);
                var all_form;

                $changeContainer.html($form.html(min_form)).css({ display: 'none' }).slideDown();
                if (!$changeContainer.is('.show')) {
                    $changeContainer.addClass('show').slideDown()
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.log(e);
                }
                initialize_delivery();
                $('.type_adresse', '.choix_type_adresse.delivery').on('click', function() {
                    $(this).show_adresse_title($('.type_adresse', '.choix_type_adresse.delivery'), '#adresseTitre');
                });

                var country_select = '';

                if (typeof $("#pays").val() != 'undefined') {

                    country_select = $("#pays").find(":selected").data('role').toLowerCase();

                    $("#pays").change(function() {

                        if (typeof $(this).find(":selected").data('role') != 'undefined') {

                            country_select = $(this).find(":selected").data('role').toLowerCase();

                        }

                        if (typeof $("#telephone").val() != 'undefined') {

                            $("#telephone").intlTelInput("selectCountry", country_select);

                        }

                        if (typeof $("#mobile").val() != 'undefined') {

                            $("#mobile").intlTelInput("selectCountry", country_select);

                        }

                    });

                } else {

                    country_select = "fr";

                }

                if (typeof $("#telephone").val() != 'undefined') {

                    $("#telephone").intlTelInput({

                        utilsScript: path_relative_root + 'js/intlTelInputUtils.js?' + Math.floor(new Date().getTime() / 1000),
                        defaultCountry: country_select

                    });

                }
                if (typeof $("#mobile").val() != 'undefined') {

                    $("#mobile").intlTelInput({

                        utilsScript: path_relative_root + 'js/intlTelInputUtils.js?' + Math.floor(new Date().getTime() / 1000),
                        numberType: "MOBILE",
                        defaultCountry: country_select

                    });
                }
            }
        });

    }
};

/* Initialise le swiper pour le home module: slider-module et prend en compte plusieurs utilisations du même module */
if ( $( '.home-module.slider-module' ).length ) {
    let sliderModuleCount = 1;

    $( '.home-module.slider-module' ).each( function () {
        let newSwiperClass = 'slider-module-swiper-' + sliderModuleCount;
        let newPaginationClass = 'slider-module-pagination-' + sliderModuleCount;

        $( this ).find( '.swiper-slider-module' ).addClass( newSwiperClass );
        $( this ).find( '.swiper-pagination' ).addClass( newPaginationClass );

        if ( $( this ).find( '.swiper-slide' ).length > 1 ) {
            let mySwiper = '.slider-module-swiper-' + sliderModuleCount;
            let myPagination = '.slider-module-pagination-' + sliderModuleCount;

            const swiper_slider_module = new Swiper( mySwiper, {
                slidesPerView: 1,
                loop: true,
                speed: 2200,
                autoplay: {
                    delay: 4500
                },
                allowTouchMove: true,
                pagination: {
                    el: myPagination,
                    type: 'bullets',
                    clickable: true,
                },
            } );
        }

        ++sliderModuleCount;
    } );
}

/* Pour le home module 'image-text-cta', ajuste la taille du rollover correctement */
function resizeHomeModuleRollover() {
    let tag = $( '.image-text-cta' ).find( '.tag' );
    let imgWrapper = $( '.image-text-cta' ).find( '.imgWrapper' );

    tag.each( function () {
        let item = $( this ).find( '.item' );
        let itemWidth = ( ( 20 / 100 ) * imgWrapper.width() );

        $(item).css( 'width', itemWidth);

        /* Positionne les rollover correctement */
        if ( $( this ).hasClass( 'rightPos' ) ) {
            $( item ).css( 'right', itemWidth );
        }
        if ( $( this ).hasClass( 'bottomPos' ) ) {
            $( item ).css( 'bottom', (50 + itemWidth) );
        }
    } );
};

if ( $( '.image-text-cta' ).length ) {
    resizeHomeModuleRollover();
}

if ($(".ais-InstantSearch")){
    window.addEventListener("load", function (){
        $(".ais-InstantSearch").addClass("load")
    })
}

// Checks whether email adresss valid
function validateEmail(email) {
    var rex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    return rex.test(email);
}
function createLineWoosmap(store, MapsProviderService){
    let div = '';
        let storeDetailsUrl = 'store_details.php?id=' + store.properties.store_id;
        let zipcodeAndCity = '';
        if (zipcode = store.properties.address.zipcode) {
            zipcodeAndCity = zipcode;
        }
        if (city = store.properties.address.city) {
            zipcodeAndCity += ' ' + this.formatAddress(city);
        }
        zipcodeAndCity = zipcodeAndCity.trim();
        if (MapsProviderService.device != null && MapsProviderService.device == 'mobile') {
            div = `<div class="bloc_marker_info">
                <p style="font-size:12px">
                    <span class="marker_store_name">${store.properties.name}</span>
                    <p style="font-weight:bold;margin-top:10px;">Adresse</p>
                    <span class="marker_store_adr">${store.properties.address.lines.join('<br>')}</span>
                    <span class="marker_store_cp">${zipcodeAndCity}</span>
                </p>
                <div class="cta_marker_info">
                    <span class="learn_more"><a href="${storeDetailsUrl}">${Translator.translate('learn_more')}</a></span>
                    <a class="marker_go_map" href="">${Translator.translate('go_to_store')}</a>
            </div>`;
        } else {
            div = '<div class="info_popup">';

            div += '<div class="name"><a href="' + storeDetailsUrl + '">' + store.properties.name + '</a></div>';

            div += '<div class="adresse"><p style="font-weight:bold;margin-top:10px;">' + Translator.translate('adresse') + '</p>';
            div += formatAddress(store.properties.address.lines.join('<br>'));

            if (zipcodeAndCity.length > 0) {
                div += '<br>' + zipcodeAndCity
            }
            if (country = store.properties.address.countryCode) {
                div += '<br>' + country;
            }
            div += '</div>';
            // div += '<div class="store_infos">' + formatPhone(store.properties.contact.phone) + '</div>';

            div += '<div class="horaire_info"><p class="horaire_title" style="font-weight:bold;margin-top:10px;">' + Translator.translate('horaires_map') + '</p>';
            let openingTimes = [];
            for (weekday = 1; weekday <= 7; weekday++) {
                if (store.properties.weekly_opening) {
                    let hours = store.properties.weekly_opening[weekday].hours;
                    let dayOpeningTimes = [];
                    if (store.properties.weekly_opening[weekday].hours.length > 0) {
                        for (let i in hours) {
                            if (hours[i].start.length > 0 && hours[i].end.length > 0) {
                                dayOpeningTimes.push(hours[i].start + '-' + hours[i].end);
                            }
                        }
                    } else {
                        dayOpeningTimes.push(Translator.translate('delivery_store_closed'));
                    }
                    dayOpeningTimes = Translator.translate('day_0' + weekday) + ' : ' + dayOpeningTimes.join(', ')
                    if (store.properties.open.open_now && store.properties.open.weekday == weekday) {
                        dayOpeningTimes = '<span class="currently_open">' + dayOpeningTimes + '</span>';
                    }
                    openingTimes.push(dayOpeningTimes);
                }
            }
            div += openingTimes.join('<br>');
            div += '</div>';

            div += '<br><a class="savoir_plus" href="' + storeDetailsUrl + '">' + Translator.translate('popup_shop_info') + '</a>';

            div += '</div>';
        }
        return div;
}
function formatAddress (address) {
    return address.charAt(0).toUpperCase() + address.slice(1).toLowerCase();
}

function elemGlobalShow() {
    $(".elem_global.delivery").show();
}

/**
 * @description on the login form, mirror the "no" radio button to the separated 'checkbox' at the bottom & observe the radio button and update the checkbox accordingly, and vice versa
 */
function mirrorOptoutCheckbox() {
    var fakeOptoutCheckbox = '#deny-optin input[type="radio"]';
    var fakeOptoutCheckboxContainer = '#deny-optin';
    var trueOptoutCheckboxSignup = '#js-signUpForm input[type="radio"][value="0"]';
    var trueOptoutCheckboxLogin = '#auth input[type="radio"][value="0"]';
    var trueOptinCheckboxSignup = '#js-signUpForm input[type="radio"][value="1"]';
    var trueOptinCheckboxLogin = '#auth input[type="radio"][value="1"]';

    // bind the event to the body, so it works even if the form is loaded after the script
    // if any radio button is changed, check if it corresponds to any of the 'checkboxes'
    document.addEventListener('change', function (e) {
        if (e.target.matches(fakeOptoutCheckbox)) {
            try {
                document.querySelector(trueOptoutCheckboxSignup).checked = e.target.checked;
            } catch (error) {
                console.warn('Error occurred while handling trueOptoutCheckboxSignup:', error);
            }

            try {
                document.querySelector(trueOptoutCheckboxLogin).checked = e.target.checked;
            } catch (error) {
                console.warn('Error occurred while handling trueOptoutCheckboxLogin:', error);
            }
        }

        if (e.target.matches(trueOptinCheckboxSignup) || e.target.matches(trueOptinCheckboxLogin)) {
            document.querySelector(fakeOptoutCheckbox).checked = !e.target.checked;
        }
    });

    // we need to add an observer to the body, if at any point the radio button is changed, we need to make sure the user isn't stuck
    var observer = new MutationObserver(function (mutations) {
        let fakeCheckboxStatus = "neutral"; // neutral, toHide, toShow

        mutations.forEach(function (mutation) {
            // if at any point one of the true elements is removed from the dom, hide the fake checkbox
            if (mutation.removedNodes) {
                if (!document.querySelector(trueOptinCheckboxSignup) && !document.querySelector(trueOptinCheckboxLogin)) {
                    // if the true optin checkbox is removed, fade out the fake checkbox
                    fakeCheckboxStatus = "toHide";
                }
            }

            // if at any point one of the true elements is added to the dom, check its state and update the fake checkbox accordingly
            if (mutation.addedNodes) {

                if (document.querySelector(trueOptinCheckboxSignup) || document.querySelector(trueOptinCheckboxLogin)) {
                    // if the true optin checkbox is added, fade in the fake checkbox
                    fakeCheckboxStatus = "toShow";
                }

                // if the true optout checkbox is added, check its state and update the fake checkbox accordingly
                try {
                    if (document.querySelector(trueOptoutCheckboxSignup)) {
                        if (document.querySelector(trueOptoutCheckboxSignup).checked) {
                            // if the true optout checkbox is checked, check the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = true;
                        } else {
                            // if the true optout checkbox is unchecked, uncheck the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = false;
                        }
                    }
                } catch (error) {
                    console.warn('Error occurred while handling trueOptoutCheckboxSignup:', error);
                }

                try {
                    if (document.querySelector(trueOptoutCheckboxLogin)) {
                        if (document.querySelector(trueOptoutCheckboxLogin).checked) {
                            // if the true optout checkbox is checked, check the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = true;
                        } else {
                            // if the true optout checkbox is unchecked, uncheck the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = false;
                        }
                    }
                } catch (error) {
                    console.warn('Error occurred while handling trueOptoutCheckboxLogin:', error);
                }
            }
        });

        // update the fake checkbox status depending on the state of the fakeCheckboxStatus variable
        if (fakeCheckboxStatus === "toHide") {
            $(fakeOptoutCheckboxContainer).fadeOut();
        } else if (fakeCheckboxStatus === "toShow") {
            $(fakeOptoutCheckboxContainer).fadeIn();
        }
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
}

window.addEventListener('DOMContentLoaded', () => {
    const pageAdvance = document.querySelector('body.cms_page_new') ? document.querySelector('body.cms_page_new') : document.querySelector('body.cms_page');
    const anchor = document.querySelector('.ancre_type');

    // check if there is a hash (id) in the url and scroll to the element with this hash (id) in the dom
    if (pageAdvance) {
        if (anchor) {
            setTimeout(() => {
                if (window.location.hash) {
                    const element = document.getElementById(window.location.hash.substring(1));
                    if (element) {
                        $("html, body").animate({scrollTop: $(element).offset().top}, 600);
                    }
                }
            }, 600);
        }
    }

    // we need to wait for the document to be fully loaded, so we can triiger the function mirrorOptoutCheckbox()
    mirrorOptoutCheckbox();
});

if ($('.title_container_bundle').length) {
    $('.title_container_bundle').each(function() {
        $(this).on('click', function () {
            if ($(this).hasClass('actif')) {
                $(this).removeClass('actif');
                $(this).siblings('.bundle_container').removeClass('actif');
            } else {
                $(this).addClass('actif');
                $(this).siblings('.bundle_container').addClass('actif');
            }
        });
    });
}

setTimeout(function () {
    $('.bundle_carac .caracs_container').overlayScrollbars({
        overflowBehavior: {
            x: 'hidden',
        },
    });
}, 250);

function displayCaracPic(event) {
    $('.displayed_bundle_image .notice_image').on('click', function() {
        let carac_pic = $(this);
        let carac_pic_src = carac_pic.attr('src');

        $('.desc_pic_zoom .pic_holder img').attr('src', '');
        $('.desc_pic_zoom .pic_holder img').attr('src', carac_pic_src);

        // Once image is updated, open lightbox zoom
        openMultiShad('desc_pic_zoom');
    })
}

$(document).on('click', '.bask_action .remove_top_wishlist', function() {
    setTimeout(function() {
        $('#shad_menu').removeClass('actif');
        $('#wrapper_top_menu').removeClass('shaded');
    }, 2500);
});

function basketToWishExtra() {
    $(".block_top_links #wishlist_top").addClass('hasItem');
}

// Initialize all Dailymotion videos present in the DOM using the Dailymotion Web SDK.
// This ensures proper integration, autoplay / pause features.
function initDailymotionVideos() {
    $(".player-container-dailymotion").each(function () {
        try {
            dailymotion.createPlayer($(this).attr('id'), {
                video: $(this).attr('data-video-id'),
                params: {
                    loop: true,
                    mute: true
                }
            }).then(dailymotionPlayer => {
                if (dailymotionPlayer) {
                    $(".dailymotion-player-root").removeAttr("style").attr("style", "padding-bottom: 0 !important;position: static !important;margin-left: -250px !important;");
                    $(".dailymotion-player-wrapper").removeAttr("style");
                    const handleIntersections = (entries) => {
                        entries.forEach(entry => {
                            if (entry.isIntersecting) {
                                dailymotionPlayer.play();
                            } else {
                                dailymotionPlayer.pause();
                            }
                        });
                    };
                    const observer = new IntersectionObserver(handleIntersections, {threshold: 0.5});
                    observer.observe(document.getElementById($(this).attr('id')));
                } else {
                    console.error("Aucun élément vidéo ou audio trouvé dans le conteneur du lecteur.");
                }
            }).catch((e) => console.error(e));
        } catch (error) {
            console.error("Une erreur sest produite lors de la création du lecteur :", error);
        }
    });
}

$(document).ready(function () {
    initDailymotionVideos();
});
